export interface CapacitacionFila {
    id: number;
    codigo: string;
    categoria: number;
    nombre: string;
    descripcion: string;
    horas: number
    short: string;
    estatus: number;
    usuario: number;
    lista: any;
    tags: number[];
}
export interface Capacitacion {
    id: number;
    categoria: number;
    codigo: string;
    nombre: string;
    descripcion: string;
    contenido: any;
    horas: number
    props: any;
    data: any;
    short?: string;
    resumen: any;
    estatus: number;
    usuario: number;
    tags: number[];
}
export const CapacitacionNull: Capacitacion = {
    id: 0,
    codigo: '',
    categoria: 0,
    nombre: '',
    descripcion: '',
    contenido: {},
    horas: 0,
    props: {},
    data: {},
    short: '',
    resumen: {},
    estatus: 0,
    usuario: 0,
    tags: []
};