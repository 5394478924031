import { Modal } from 'react-bootstrap';
import Nuevo from '../Unlogin/Nuevo';
import './Modal.css';
interface Props {
    open: boolean,
    close: () => void,
}
export default function ModalRegister({ close, open }: Props) {
    return <Modal show={open} size='xl' centered onHide={close}>
        <Modal.Header closeButton>
            <h2 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                <span><b>Registrarse</b></span>
            </h2>
        </Modal.Header>
        <Modal.Body>
            <Nuevo />
        </Modal.Body>
    </Modal>
}