import { useContext, useEffect } from 'react'
import { LandingContext } from './Context';
import { Preview } from '../../../Panels';
import ProgramaVer from '../Ver';
import { Suscribir2 } from './Panels/Suscribir2';
import { Cargando } from '@victorequena22/component-bootstrap';
import { ChatContext } from '../../../Template/ChatContext';
import { useParams } from 'react-router-dom';
import { findProgram } from '../../Context/Programas';
import Metricas from '../../../Config/Metricas';
import { defaultHeader, updateHeader } from '../../../Config/header';
export function Ver() {
    const { panels, cargando } = useContext(LandingContext);
    const p = panels.filter(p => p.indice > 0 && p.estado > 0).sort((a, b) => a.indice - b.indice);
    const { setText } = useContext(ChatContext);
    const { id } = useParams<{ id: any }>();
    const po = findProgram(id);
    useEffect(() => {
        Metricas().setMetricasNav('programa', po.id);
        updateHeader(po.nombre, po.descripcion, po.short ? po.short : '');
        return () => defaultHeader();
    }, [po.id, po.nombre, po.descripcion, po.short])
    useEffect(() => {
        if (po.id !== 0) setText(`Hola quiero más información sobre ${po.nombre}`);
        return () => setText('');
    }, [po, setText])
    if (cargando) return <Cargando />
    if (p.length === 0) return <ProgramaVer />
    return <>
        {p.map(p => <div key={'content' + p.type + p.id + id} className='w-100 m-0 p-0'>
            <Preview2 panel={p} key={'panel' + p.type + p.id + id} />
        </div>)}
    </>
}
function Preview2({ panel }: { panel: any }) {
    switch (panel.type) {
        case 'suscribir2': return <Suscribir2 key={'panel' + panel.id} data={panel} />
        default: return <Preview key={'panel' + panel.id} panel={panel} />
    }
}