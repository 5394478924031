import { isLine } from '../../../Config/OnLineCheker';
import { EventoLista } from './Model';
import { ConexionAvanzada, setCambio, setData } from '@victorequena22/conneccion';
class EventoConecction extends ConexionAvanzada {
    clase = 'Evento';
    async Suscribirse(id: any, setData?: setData) {
        try {
            const data = await this.getProxi(`/Suscribirse/Participante/${id}`);
            if (setData) setData(await data);
        } catch (e) {
            if (isLine()) console.log(e);
        }
    }
    Suscribir(id: any, data: any, setData?: setData, setError?: setData) {
        this.postProxi(`/Suscribirse/telefono/${id}`, data, setData, setError);
    }
    async normas(codigo: string | number, setData: setData) {
        try {
            const data = await this.getProxi('/Norma/detalles/' + codigo);
            setData(await data);
        } catch (err) {
            if (isLine()) console.log(err);
        }
    }
    async consideraciones(codigo: string | number, setData: setData) {
        try {
            const data = await this.getProxi('/Consideracion/detalles/' + codigo);
            setData(await data);
        } catch (err) {
            if (isLine()) console.log(err);
        }
    }
    async aval(codigo: string | number, setData: setData) {
        try {
            const data = await this.getProxi('/Aval/detalles/' + codigo);
            setData(await data);
        } catch (err) {
            if (isLine()) console.log(err);
        }
    }
    async inscribirse(codigo: string | number, setData: setData) {
        try {
            const data = await this.getProxi('/Certificados/Participante/inscribir/' + codigo);
            setData(await data);
        } catch (err) {
            if (isLine()) console.log(err);
        }
    }
    async encuesta(evento: string | number, respuesta: string, setData: setData) {
        this.postProxi('/api/Evento/Encuesta/nuevo', { evento, respuesta }, setData);
    }
}
export function conEvento() {
    return new EventoConecction();
}
export function arrayToEvento(f: any[]): EventoLista[] {
    return f.map((a) => {
        return {
            id: a[0],
            short: a[1],
            codigo: a[2],
            programa: a[3],
            cohorte: a[4],
            nombre: a[5],
            descripcion: a[6],
            fecha: a[7],
            inicio: a[8],
            final: a[9],
            pais: a[10],
            ciudad: a[11],
            estado: a[12],
            direccion: a[13],
            estatus: a[14],
            usuario: a[15],
            publicacion: a[16],
            lista: a[17],
            facilitadores: a[18],
            avales: a[19],
            tags: a[20],
            landing: a[21],
        }
    });
}
function setCambioEvento(data: any[], c: EventoLista[]) {
    return setCambio(arrayToEvento(data), c);
}
export const eventoUpdate = (c: EventoConecction, u: EventoLista[], setData: (c: EventoLista[]) => void) => {
    c.cambios(data => setData(setCambioEvento(data, u)));
}