import { formatoFecha, numberFormat } from "@victorequena22/utiles";
import { Pago } from "../../../Pagos/Models";

export function FilaPago({ pago: { referencia, banco, monto, nota, fecha, estatus } }: { pago: Pago }) {
    const badge = ['danger', 'warning', 'success'];
    const estados = ['Rechazado', 'Pendiente', 'Aprobado'];
    return <tr>
        <td>{formatoFecha(fecha)}</td>
        <td>{banco}</td>
        <td>{referencia}</td>
        <td>{numberFormat(monto)}</td>
        <td><span className={`badge badge-${badge[estatus]}`}>{estados[estatus]}</span></td>
        <td>{nota}</td>
    </tr>
}