
export function UseIcon({ width, height, icon, fill }: { fill: string, width: number | string, height: number | string, icon: any }) {
    return <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${icon.width} ${icon.height}`}>
        {typeof icon.path === 'string' ? <path style={{ fill }} d={icon.path} /> :
            (icon.path as string[]).map((t, i) => <path key={`path${t}${i}`} style={{ fill, opacity: i === 0 ? .7 : undefined }} d={t} />)}
    </svg>
}



export function Icon2({ font, fill, icon, back, border, aux }: { aux: string, border: boolean, fill: string, font: number, icon: any, back: string }) {
    return <div className="text-center mr-auto ml-auto" style={{
        width: `${font * 1.3}rem`, height: `${font * 1.3}rem`,
        borderRadius: back === 'circle' ? `${font}rem` : back === 'rounded' ? `${font * .3}rem` : undefined,
        background: back !== 'none' ? aux : undefined, padding: `${font * .1}rem`,
        border: (border && back !== 'none') ? `${font * .05}rem solid ${fill}` : undefined,
    }}>
        <UseIcon icon={icon} width={`${font}rem`} height={`${font}rem`} fill={fill} />
    </div>
}