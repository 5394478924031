import React, { useState } from "react"
import { numberFormat } from "@victorequena22/utiles";
import { OutlinedInput } from "@mui/material";
import { focusId } from "../../../Utils/Formatos";
export interface Props {
    setData: (data: any) => void,
    value: number
    update?: string
    min?: number
    max?: number
    decimal?: number
    next?: () => void
    input?: React.HTMLProps<HTMLInputElement>
}

export default function FieldNumber({ setData, value, update, decimal, min, max, next, input }: Props) {
    const [select, setSelect] = useState(false);
    const [id] = useState(`inputNumber${update}${Math.random()}`);
    const getValue = () => {
        if (select) return { type: 'number' };
        else return {}
    }, Props = () => {
        const props: any = { ...input };
        let style = input ? input.style : {};
        style = { textAlign: 'right', ...style };
        props.value = undefined;
        props.style = undefined;
        return { ...props, ...getValue(), style };
    }
    return <OutlinedInput key={`${update}${select}`} aria-describedby={`${update}-error`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (input && input.onChange) input.onChange(e);
            let { value } = e.currentTarget;
            let r = (value !== '') ? parseFloat(value) : 0;
            if (min) if (min > r) r = min;
            if (max) if (max < r) r = max;
            if (update) setData({ [update]: r });
            else setData(r);
        }}
        onFocus={(e: any) => { focusId(id); setSelect(true); if (input && input.onFocus) input.onFocus(e); }}
        onBlur={(e: any) => { setSelect(false); if (input && input.onBlur) input.onBlur(e); }}
        onKeyUp={(e: any) => {
            if (e.keyCode === 13) if (next) next();
            if (input && input.onKeyUp) input.onKeyUp(e);
        }}
        defaultValue={select ? '' : numberFormat(value, decimal)}
        componentsProps={{ input: { ...Props(), id } }} />
}