import { Accordion, Button, Col } from "react-bootstrap";
import Programa from "../../../Programas/Programa";
import Consideraciones from "./Consideraciones";
import { Evento } from "../../Models";
import Normas from "./Normas";
export function DisplayData({ evento }: { evento: Evento }) {
    return <>
        <Programa id={evento.programa} />
        <Normas id={evento.norma} />
        <Consideraciones id={evento.consideracion} />
    </>
}
export function AcordionData({ evento }: { evento: Evento }) {
    return <Accordion as={Col} xs={12}>
        <Accordion.Toggle eventKey="0" block
            as={Button} variant='outline-info'>
            VER MAS
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0"><>
            <Programa id={evento.programa} />
            <Normas id={evento.norma} />
            <Consideraciones id={evento.consideracion} />
        </></Accordion.Collapse>
    </Accordion>
}