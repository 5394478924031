import Suscribirse from './Suscribirse';
import Sus from '../../../Paneles/Suscribir2';

export function Suscribir2({ data }: any) {
    const { props } = data;
    const { cardTitle } = props;
    return <Sus data={data}>
        <Suscribirse html={cardTitle} config={props} />
    </Sus>
}

