import { FormControl, InputLabel, FormHelperText } from "@mui/material";
import FieldNumber, { Props as padre } from "../Field/InputNumber";
export interface Props extends padre {
    label: string
    error?: any;
}
export default function FieldControlNumber(p: Props) {
    const { label, error, update } = p
    const isError = !!(update ? error[update] : error);
    return <FormControl error={isError} style={{ width: '100%' }}>
        <InputLabel style={{ backgroundColor: 'white' }} htmlFor={update}>{label}</InputLabel>
        <FieldNumber {...p} />
        {isError ? <FormHelperText id={`${update}-error`}>
            {update ? error[update] : error}
        </FormHelperText> : <></>}
    </FormControl>
}