import { useLandingEvento } from './Context';
import { Preview } from '../../../Panels';
import { Suscribir2 } from './Panels/Suscribir2';
import { Cargando } from '@victorequena22/component-bootstrap';
export function Ver() {
    const { cargando, panels } = useLandingEvento();
    if (cargando === true) return <Cargando />
    return <>
        {panels.map(p => <div key={'content' + p.type + p.id} className='w-100 m-0 p-0'>
            <Preview2 key={'panel' + p.type + p.id} panel={p} />
        </div>)}
    </>
}
function Preview2({ panel }: { panel: any }) {
    switch (panel.type) {
        case 'suscribir2': return <Suscribir2 key={'Preview' + panel.id} data={panel} />
        default: return <Preview key={'Preview' + panel.id} panel={panel} />
    }
}