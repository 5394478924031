import { Suspense, lazy, useEffect } from 'react';
import Menu from './Template/Menu';
import { ToastContainer } from 'react-toastify';
import './Noti.css';
import Footer from './Template/Footer';
import { Route } from 'react-router-dom';
import { Capacitacion } from './Capacitacion';
import ClaveRecuperar from './Capacitacion/Participante/Area/ClaveRecuperar';
import ContextProvider from './Capacitacion/Context';
import Metricas from './Config/Metricas';
import { ChatProvider } from './Template/ChatContext';
import PageView from './Panels';
import { chats } from './Data';
import Cargando from './Components/Cargando';
const Chat = lazy(() => import("./Template/Chat"));
const Mis = lazy(() => import("./Capacitacion/Participante"));
export default function App() {
  useEffect(() => { Metricas().setMetricas(); }, [])
  return (<ContextProvider><ChatProvider><Suspense fallback={<Cargando />}>
    <Menu />
    <Route path="/" exact><PageView /></Route>
    <Route path={["/categotia", "/programa", "/facilitador", "/f", "/e", "/facilitadores", "/programas", "/evento", "/eventos"]}><Capacitacion /></Route>
    <Route path='/recuperar/clave/:code'><ClaveRecuperar /></Route>
    <Route path='/mis'><Mis /></Route>
    {chats.activo ? <Chat /> : <></>}
    <Footer />
    <ToastContainer position='bottom-right' />
  </Suspense></ChatProvider></ContextProvider>);
}


