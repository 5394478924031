import { useID } from "@/Utils/ReactUtils";
import { findEventoFila } from "../Context/Eventos";
import LandingEvento from "./Landing";
import InscribirEvento from "./Ver";
import { useContext, useEffect } from "react";
import { ChatContext } from "@/Template/ChatContext";
import Metricas from "@/Config/Metricas";
import { defaultHeader, updateHeader } from "@/Config/header";




export default function Manager() {
    const { setText } = useContext(ChatContext);
    const po = findEventoFila(useID());
    useEffect(() => {
        Metricas().setMetricasNav('evento', po.id);
        updateHeader(po.nombre, po.descripcion, po.short ? po.short : '');
        return () => defaultHeader();
    }, [po.id, po.nombre, po.descripcion, po.short])
    useEffect(() => {
        if (po.id !== 0) setText(`Hola quiero más información sobre ${po.nombre}`);
        return () => setText('');
    }, [po, setText])
    if (po.landing) return <LandingEvento />
    return <InscribirEvento />
}