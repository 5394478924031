/* eslint-disable no-new-func */
import { Categoria } from "../Programas/Categorias/Models";
import { MetodoPago } from "../Pagos/MetodosPago/Models";
import { Tags } from "../../Media/Models";
import { CapacitacionFila, arrayToCapacitacion } from "../Programas/Models";
import { EventoLista, arrayToEvento } from "../Evento/Models";
import { FacilitadorFila } from "../Facilitadores/Models";
//DATA 
const metodos: MetodoPago[] = Function('return metodos')();
const facilitadores: FacilitadorFila[] = Function('return facilitadores')();
const tags: Tags[] = Function('return tags')();
const categorias: Categoria[] = Function('return categorias')();
const getEventos = () => arrayToEvento(Function('return eventos.data')())
const getProgramas = () => arrayToCapacitacion(Function('return programas.data')())
const eventos: EventoLista[] = getEventos();
const programas: CapacitacionFila[] = getProgramas();
export { facilitadores, metodos, tags, categorias, eventos, programas };