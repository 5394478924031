import { useContext } from 'react'
import { LandingContext } from './Context';
import { Preview } from '../../../Panels';
import VerFacilitador from '../Ver';
import { Cargando } from '@victorequena22/component-bootstrap';
export function Ver() {
    const { panels, cargando } = useContext(LandingContext);
    const p = panels.filter(p => p.indice > 0 && p.estado > 0).sort((a, b) => a.indice - b.indice);
    if (cargando) return <Cargando />
    if (p.length === 0) return <VerFacilitador />
    return <>
        {p.map(p => <div key={p.type + p.id} className='w-100 m-0 p-0'>
            <Preview panel={p} />
        </div>)}
    </>
}
