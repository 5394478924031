import { ConexionCompleta, setData } from '@victorequena22/conneccion';


class PagoConecion extends ConexionCompleta {
    clase = 'Pagos';
    pagosEvento = async (id: number, setData: setData) => {
        try {
            const data = await this.getProxi('/pagosEvento/' + id);
            if (Array.isArray(await data)) {
                if (await data.length !== 0) {
                    setData(await data);
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

}
class DolarConecion extends ConexionCompleta {
    clase = 'Dolares';
    valor = async (setData: setData) => {
        try {
            const data = await this.getProxi('/valor');
            if (Array.isArray(await data)) {
                if (await data.length !== 0) {
                    setData(await data[0]);
                }
            }
        } catch (err) {
            console.log(err);
        }
    }
}
export function conPago() {
    return new PagoConecion();
}
export function conDolar() {
    return new DolarConecion();
}