import { Col, Container, Image, Row, Card, Accordion } from "react-bootstrap";
import { getEspecialidades } from "../Especialidades";
import { getProgramas } from "../../Context/Programas";
import { Html } from "../../../Components/Html";
import { UseIcon } from "../../../Components/Icons";
import { Facilitador } from "../Models";
import { useEffect } from "react";
import { defaultHeader, updateHeader } from "../../../Config/header";
import { getImagenURL } from "../../../Utils/Imagen";

export default function VistaFacilitador({ f }: { f: Facilitador }) {
    const { especialidades: e } = getEspecialidades();
    const pr = getProgramas();
    useEffect(() => {
        updateHeader(f.nombre, '', f.foto);
        return () => defaultHeader();
    }, [f.id, f.nombre, f.foto])
    return <Row>
        <Container>
            <Row>
                <Col lg={4}>
                    <Col lg={12}><Image style={{ aspectRatio: "1 / 1 !important" }} fluid src={getImagenURL(f.foto)} alt={f.nombre} /></Col>
                    <Col lg={12} className="text-center">
                        <label>REDES SOCIALES</label>
                        <Col xs={12} className="text-center">
                            {f.redes.map((e: any) => <a key={e.url} href={e.url} className="m-1" data-tip={e.nombre} target="_blank" rel="noreferrer">
                                <UseIcon key={`UseIcon${e.url}`} width='2.5rem' height='2.5rem' icon={e.icon} fill='currentcolor' />
                            </a>)}
                        </Col>
                    </Col>
                </Col>
                <Col lg={8} style={{ height: 'fit-content' }} className="m-0 p-0">
                    <Row style={{ height: 'fit-content' }} className="m-2  p-0">
                        <Col lg={12} style={{ height: 'fit-content' }}><h1 className="text-info"><b>{f.nombre}</b></h1></Col>
                        <Col lg={12} style={{ height: 'fit-content' }} className="mt-2"><b>Años de experiencia: </b>{(new Date()).getFullYear() - f.experiencia}</Col>
                        <Col lg={12} style={{ height: 'fit-content' }} className="mb-auto mt-2">
                            <Html html={f.descripcion} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="m-2">
                <Col lg={12} className="mt-2">
                    <label><b>ÁREAS DE EXPERIENCIA Y\O CONOCIMIENTOS</b></label>
                    <ul className="mb-2 row">
                        {e.filter(([id]) => f.especialidades.includes(id)).map(([id, nombre]) =>
                            <Col key={`Area${id}`} md={3} as='li'>{nombre.replaceAll('¤', '"')}</Col>)}
                    </ul>
                </Col>
                <Col lg={12}>
                    <Card>
                        {f.estudios.length > 0 && f.estudios ? <AC title="ESTUDIOS REALIZADOS">
                            <ul>{f.estudios.map((e: any) => <li key={`Estudio${e.titulo}`}><b>{e.grado.replaceAll('¤', '"')}:</b> {e.titulo.replaceAll('¤', '"')}</li>)}</ul>
                        </AC> : <></>}
                        {f.trabajos.length > 0 && f.trabajos ? <AC title="EXPERIENCIA LABORAL">
                            <ul>{f.trabajos.map((e: any) => <li key={`Estudio${e.cargo}`}>
                                <b>{e.inicio}-{e.final !== '' ? e.final.replaceAll('¤', '"') : 'presente'}:</b> {e.cargo.replaceAll('¤', '"')}
                            </li>)}</ul>
                        </AC> : <></>}
                        {f.programas.length > 0 && f.programas ? <AC title="PROGRAMAS QUE FACILITA" primero>
                            <ul>{pr.filter(p => f.programas.includes(p.id))
                                .map(e => <li key={`Programa${e.id}`}>{e.nombre.replaceAll('¤', '"')}</li>)}</ul>
                        </AC> : <></>}
                        {/* <AC title="PUBLICACIONES REALIZADAS">
                            <ul>{f.estudios.map(e => <li key={e.titulo}><b>{e.grado}:</b> {e.titulo}</li>)}</ul>
                        </AC> */}
                        {f.premios.length > 0 && f.premios ? <AC title="PREMIOS RECIBIDOS">
                            <ul>{f.premios.map((e: any) => <li key={`Premio${e.premio}`}>
                                <b>{e.ano}:</b> {e.premio.replaceAll('¤', '"')} por "{e.organizacion.replaceAll('¤', '"')}"
                            </li>)}</ul>
                        </AC> : <></>}
                    </Card>
                </Col>
            </Row>
        </Container>
    </Row>
}
function AC({ title, children, primero }: { children: any, title: string, primero?: boolean }) {
    return <Accordion defaultActiveKey='0'>
        <Accordion.Toggle as={Card.Header} eventKey='a' style={{ backgroundColor: 'rgba(113, 113, 113,.3)', borderRadius: primero ? undefined : 0 }}>
            <Row>
                <label>{title.replaceAll('¤', '"')}</label>
            </Row>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='a'>
            <Card.Body>
                {children}
            </Card.Body>
        </Accordion.Collapse>
    </Accordion>
}