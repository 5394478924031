/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useContext, useEffect, useCallback } from 'react'
import { conCommos } from '../../Config/Coneccion';
import { Tags } from '../../Media/Models';
import { tags } from './Data';

export interface TagsType {
    Tags: Tags[]
    actualizar: () => void
}
export const TagsContext = createContext<TagsType>({
    Tags: tags, actualizar: () => { },
});
export default function TagsProvider({ children }: { children: any }) {
    const [Tags, setTags] = useState<Tags[]>(tags);
    const actualizar = useCallback(() => conCommos().tags(setTags), []);
    useEffect(() => { actualizar(); }, []);
    return <TagsContext.Provider value={{ Tags, actualizar }}>
        {children}
    </TagsContext.Provider>
}
export const SeccionConsumer = TagsContext.Consumer;


export function findTags(ids: number[]): Tags[] {
    const { Tags } = useContext(TagsContext);
    return Tags.filter(t => ids.some(id => t.id === id));
}
export function allTags(ids: number[]): Tags[] {
    const { Tags } = useContext(TagsContext);
    return Tags.filter(t => ids.some(id => t.id === id));
}