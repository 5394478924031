import { Container } from 'react-bootstrap';
import { Invert } from '../Components/Invert';
import ReactPlayer from 'react-player';
export default function Video({ data }: { data: any }) {
    const { props: { padding, video, backgroundColor: c, } } = data;
    return <div style={{ paddingBlock: padding, background: c, }}>
        <Container>
            <Invert data={data}>
                <ReactPlayer width='100%' controls url={video} />
            </Invert>
        </Container>
    </div>
}

