import { ConexionBase, setData } from '@victorequena22/conneccion';


export interface ParticipanteFila {
    id: number;
    nombre: string;
    foto: string;
    nacimiento: string;
    pais: string;
    estado: string;
    ciudad: string;
    direccion: string;
    telefono: string;
    correo: string;
    estatus: number;
}
export interface Participante extends ParticipanteFila {
    nombre2: null | string
    usuario: string;
    clave: string;
    code: string;
    referido: string;
    Profesión: string;
    Ocupación: string;
    documento: string;
    data: any;
    props: any;
    fecha: string;
    actualizado: string;
}
export const ParticipanteNull: Participante = {
    id: 0,
    nombre: '',
    nombre2: '',
    foto: '',
    nacimiento: '',
    pais: '',
    estado: '',
    ciudad: '',
    direccion: '',
    telefono: '',
    correo: '',
    estatus: 1,
    usuario: '@',
    clave: '',
    code: '',
    referido: '',
    Profesión: '',
    Ocupación: '',
    documento: '',
    data: {},
    props: {},
    fecha: '',
    actualizado: ''
}
type parRest = [number, string, string, string, string, string, string, string, string, string, number]
export function arrayToParticipante(pars: parRest[]) {
    return pars.map(p => {
        return {
            id: p[0],
            foto: p[1],
            nombre: p[2],
            nacimiento: p[3],
            pais: p[4],
            ciudad: p[5],
            estado: p[6],
            direccion: p[7],
            telefono: p[8],
            correo: p[9],
            estatus: p[10],
        }
    })
}

class ParticipanteConecction extends ConexionBase {
    clase = 'Participante';
    nuevo = (data: any, setData?: setData, setError?: setData) => {
        this.postProxi('/register', data, setData, setError)
    }
    actualizar = (data: any, setData?: setData, setError?: setData) => {
        this.postProxi('/complete', data, setData, setError)
    }
    cambiarClave(data: any, setData?: setData, setError?: setData) {
        this.postProxi('/cambiarClave', data, setData, setError)
    }
    userForCode(code: string, setData?: setData, setError?: setData) {
        this.postProxi('/userForCode', { code }, setData, setError)
    }
}
var conOr: ParticipanteConecction;
export function conParticipante() {
    if (conOr === undefined) conOr = new ParticipanteConecction();
    return conOr;
}