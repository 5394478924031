
export interface Categoria {
    id: number;
    nombre: string;
    short: string;
    codigo: string;
    estatus: number
}
export const categoriaNull: Categoria = {
    id: 0,
    nombre: '',
    short: '',
    codigo: '',
    estatus: 1
}