import { useContext } from "react";
import { SeccionContext } from "../../../../Template/Seccion";
import { PropsDel } from "../Props";
import { Dia } from "./Dias";
export function Online({ evento: { pais, ciudad, direccion, inicio, final, estatus, participantes }, }: PropsDel) {
    const { data: { seccion, User } } = useContext(SeccionContext);
    if (!seccion) return <></>
    if (estatus !== 2) return <></>
    if (pais !== 'ONLI') return <></>
    if (ciudad === '') return <></>
    const p = participantes.find(p => User.id === p.id)
    if (p === undefined) return <></>
    if (p.status === 1) return <></>
    return <Dia key={ciudad} hora={ciudad} inicio={inicio} fin={final} url={direccion} />
}