import { fechaToNumber } from "@victorequena22/utiles";
import { Precios } from "../../Models";

export function ListPrecio({ data }: { data: Precios[] }) {
    return <ul>
        {data.filter(p => {
            if (p.estatus === 2) return true;
            const dia = fechaToNumber();
            if ((fechaToNumber(p.inicio) >= dia) && fechaToNumber(p.final) <= dia) return true;
            return false;
        }).map(p => <li key={p.nombre}>
            {p.nombre} <b className="ml-auto">{p.precio}$</b>
        </li>)}
    </ul>
}