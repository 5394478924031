import { diaToNumber, zfill } from "@victorequena22/utiles";
import { Contador } from "./Contador";

export function Dia({ hora, inicio, fin, url }: { hora: string, inicio: string, fin: string, url: string }) {
    let i = diaToNumber(inicio), f = diaToNumber(fin), a = actual();
    const h = hora.split('-');
    if (f >= a && a >= i) return <Contador key={hora} inicio={h[0]} fin={h[1]} url={url} />
    return <></>
}
function actual() {
    let date = new Date();
    let año = date.getFullYear(),
        mes = date.getMonth() + 1,
        dia = date.getDate();
    let str = año + zfill(mes, 2) + zfill(dia, 2);
    return parseInt(str);
};