/* eslint-disable jsx-a11y/anchor-is-valid */
import { Cargando } from "@victorequena22/component-bootstrap";
import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Html } from "../../../../Components/Html";
import { auxiliarNull, conEvento, TypeAuxiliar } from "../../Models";

export default function Normas({ id }: { id: number }) {
    const [normas, setNormas] = useState<TypeAuxiliar>(auxiliarNull);
    useEffect(() => {
        if (id !== 0) {
            conEvento().normas(id, setNormas);
        }
    }, [id])
    if (normas.id === 0) return <Cargando />
    return <Col md={12}>
        <h3 className='w-100 text-info'><b>Normas</b></h3>
        {normas.id !== 0 ? <Html html={normas.texto} /> : <Cargando />}
    </Col>
} 