/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useContext, useEffect, useState } from "react";
import Facilitadores from "../../Facilitadores/Facilitadores";
import { Col, Container, Image, Row } from "react-bootstrap";
import { SeccionContext } from "../../../Template/Seccion";
import { findProgram } from "../../Context/Programas";
import Cargando from "../../../Components/Cargando";
import { findEvento } from "../../Context/Eventos";
import { AcordionData, DisplayData } from "./Data/DisplayData";
import { Registrarse } from "./Data/Registrarse";
import { useParams } from "react-router-dom";
import { ListPrecio } from "./Data/Precios";
import { Inscribirse } from "./Inscribirse";
import { MyStatus } from "./Data/MyStatus";
import { conEvento } from "../Models";
import Avales from "./Data/Avales";
import { PropsDel } from "./Props";
import { Pagos } from "./Pagos";
import { Online } from "./Online";
import { getImagenURL } from "../../../Utils/Imagen";
import { ListaPagos } from "./Pagos/Lista";
import { OtroPago } from "./Pagos/OtroPago";
import { PrimerPago } from "./Pagos/PrimerPago";
import Asistencia from "./Asistencia";
const detalle = (id: any, set: any) => conEvento().detalle(id, set);
export default function InscribirEvento() {
    const { id } = useParams<{ id: any }>();
    const [c, set] = useState<any>(findEvento(id));
    const [ram, setRam] = useState<any>(Math.random());
    const programa = findProgram(c.programa);
    const init = useCallback(() => { detalle(id, set); setRam(Math.random()); }, [id])
    useEffect(() => { init() }, [init])
    if (!c.facilitadores) return <Cargando />
    return <Row className='row p-0 m-0'>
        <Container>
            <Row className="p-2">
                <Col md={12} className='m-2'>
                    <h1 className='w-100 text-primary'><b>{c.nombre}</b></h1>
                </Col>
                <Col md={4}>
                    <Image fluid src={getImagenURL(c.short)} />
                </Col>
                <Col md={8} as={Row}>
                    <Col md={9}><b>Programa: </b>{programa?.nombre}</Col>
                    <Col md={3}><b>Cohorte: </b>{c.cohorte}</Col>
                    <Col md={12}><b>Fecha: </b>{c.fecha}</Col>
                    <Col md={12}><b>Descripcion: </b>{programa?.descripcion}</Col>
                    {val(c.facilitadores) ? <Col md={12}><b>Facilitado por: </b><br />
                        <Facilitadores ids={c.facilitadores} />
                    </Col> : <></>}
                    {val(c.avales) ? <Col md={12}><b>Avalado por: </b><br />
                        <Avales ids={c.avales} />
                    </Col> : <></>}
                    {val(c.precios) ? <Col md={12}><b>Precios: </b><br />
                        <ListPrecio data={c.precios} />
                    </Col> : <></>}
                </Col>
                <Col md={12}><Online key={ram} evento={c} init={init} /></Col>
                <ValStatus evento={c} init={init} />
            </Row>
        </Container >
    </Row >
}

function ValStatus({ init, evento: e }: PropsDel) {
    const { data: { User: { pagos: p, id } } } = useContext(SeccionContext);
    const pagos = p ? p.filter(p => p.model_id === e.id && p.model === "evento") : [];
    const part = e.participantes?.find(p => id === p.id)
    return <>
        <MyStatus participante={part} />
        {(e.estatus === 2 && e.pais === 'ONLI') ? <Asistencia id={e.id} /> : <></>}
        <ValStatusPagos participante={part} init={init} pagos={pagos} evento={e} />
    </>
}
function ValStatusPagos({ pagos, init, participante: part, evento: e }: PropsDel & { pagos: any[] }) {
    const { data: { seccion }, user } = useContext(SeccionContext);
    if (!seccion) return <Registrarse evento={e} />
    if (pagos.length) return <>
        <AcordionData evento={e} />
        <ListaPagos pagos={pagos} /><br />
        <OtroPago init={() => { init(); user() }} evento={e} />
    </>
    if (e.estatus === 1) return <>
        <DisplayData evento={e} />
        <PrimerPago init={() => { init(); user() }} evento={e} />
    </>
    if (e.estatus !== 1) return <AcordionData evento={e} />
    return <>
        {val(e.precios) && e.precios.length ?
            <Pagos evento={e} init={init} participante={part} /> :
            <Inscribirse evento={e} init={init} participante={part} />}
    </>
}
function val(s: any) {
    if (s === undefined) return false;
    if (s.length === undefined) return false;
    if (s === null) return false;
    return true;
}