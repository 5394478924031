import RegistrarPago from "../../../Pagos/Registrar";
import { Button } from "react-bootstrap";
import { PropsDel } from "../Props";
import { useState } from "react";

export function OtroPago({ init, evento }: PropsDel) {
    const [open, setOpen] = useState(false);
    return <>
        <RegistrarPago update={init} evento={evento} open={open} close={() => setOpen(false)} />
        <Button block size="lg" variant='success' className='m-auto' onClick={() => setOpen(true)} >Registrar otro pago</Button>
    </>
}