import { ControlText } from "@victorequena22/component-bootstrap";
import { useState } from "react";
import { FormCheck, Row } from "react-bootstrap";
interface Props {
    respuesta: string
    setRespuesta: (s: string) => void
    isError: boolean
}
export function Encuesta({ respuesta, setRespuesta, isError }: Props) {
    const [a, setA] = useState('');
    const Opt = ({ r }: { r: string }) => <Row className="ml-4 mr-4">
        <FormCheck checked={a === r} type="checkbox" onClick={() => { setA(r); setRespuesta(r); }} label={r} />
    </Row>
    return <>
        <Row className="ml-1 mr-1"><h4 ><b style={{ fontSize: isError ? '3rem' : '' }} className={isError ? 'text-danger' : ''}>¿Cómo se enteró?</b></h4></Row>
        <Opt r='Instagram' />
        <Opt r='Facebook' />
        <Opt r='Correo' />
        <Opt r='Whatsapp' />
        <Opt r='Radio' />
        <Opt r='Referido por un amigo' />
        <Row className="ml-4 mr-4">
            <FormCheck checked={a === 'Otro'} type="checkbox" onClick={() => { setA('Otro'); setRespuesta(''); }} label='Otro' />
            {a === 'Otro' ? <ControlText value={respuesta} setData={setRespuesta} /> : <></>}
        </Row>
    </>
}

