import { useState, createContext, useEffect, useCallback, useContext } from 'react';
import { semaforo } from '@victorequena22/utiles';
import { useParams } from 'react-router-dom';
import { LandingUpdate, conLanding } from '../../Landing/Landing';
export interface LandingType {
    panels: any[];
    actualizar: () => void;
    cargando: boolean
}
export const LandingContext = createContext<LandingType>({ panels: [], actualizar: () => { }, cargando: true })
export default function LandingProvider({ children }: { children: any }) {
    const { id } = useParams<{ id: any }>();
    const [cargando, setCargando] = useState(true);
    const [panels, setPanels] = useState<any[]>([]);
    const [con] = useState(conLanding());
    const actualizar = useCallback(() => LandingUpdate(con, 'facilitador', id, panels, p => {
        setPanels(p); setCargando(false);
    }, cargando, setCargando), [cargando, con, id, panels]);
    useEffect(() => { let interval = setInterval(actualizar, 50); return () => clearInterval(interval); }, [actualizar]);
    useEffect(() => { const s = semaforo(); s.add('Landing'); return () => s.clean('Landing'); }, []);
    return <LandingContext.Provider value={{ panels, actualizar, cargando }}>{children}</LandingContext.Provider>
}
export const SeccionConsumer = LandingContext.Consumer;

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useLanding = () => useContext(LandingContext);