import { useParams } from "react-router-dom";
import { interesado, user, Auth } from "../../../../../Template/Seccion";
import { conCapacitacion } from "../../../../Programas/Models";
import { findEventoFila } from "../../../../Context/Eventos";
import Participante2 from "../../../../Paneles/Suscribirse/Participante";
class Par2 extends Participante2 {
    send = () => {
        const { id } = this.props;
        conCapacitacion().interes(id, user);
    }
}
export default function Participante({ html }: { html: any }) {
    const { id } = findEventoFila(useParams<{ id: any }>().id);
    const s = interesado(id);
    const u = Auth();
    return <Par2 html={html} suscrito={s} user={u} id={id} />
}