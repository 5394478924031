import { useContext } from "react";
import { Card, Row } from "react-bootstrap";
// import { Button, ButtonGroup, Card, Col, FormControl, InputGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { EventoContext } from "../../Context/Eventos";
import { getImagenURL } from "../../../Utils/Imagen";
import { data } from "./Data";

export default function VerEventos() {
    const { backCard, TextCard } = data;
    const { eventos } = useContext(EventoContext);
    return <Row className="p-3 list-main  col-12">
        {eventos.filter(e => e.publicacion && e.estatus && e.estatus < 3)
            .map(c => <Card style={{ background: backCard, color: TextCard }}
                as={Link} to={`/evento/${c.codigo}`} className="link-lista">
                <Card.Img src={getImagenURL(c.short)} />
                <Card.Body>{c.nombre}</Card.Body>
            </Card>)}
    </Row>
}
