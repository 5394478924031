import { ConexionCompleta, isLine, setData } from '@victorequena22/conneccion';

class facilitadorConecction extends ConexionCompleta {
    clase = 'Facilitador';
    especialidades = async (setData: setData) => {
        try {
            const data = await this.getProxi(`/especialidad/lista`);
            if (setData) setData(await data);
        } catch (e) {
            if (isLine()) console.log(e);
        }
    }
    nuevoEspecialidad = async (nombre: string, setData: setData) => {
        this.postProxi('/especialidad/nuevo', { nombre }, setData);
    }
    nombre = async (nombre: string, setData: setData) => {
        try {
            const data = await this.getProxi('/nombre/' + nombre);
            if (setData) setData(await data);
        } catch (e) {
            if (isLine()) console.log(e);
        }
    }
}
var conOr: facilitadorConecction;
export function conFacilitador() {
    if (conOr === undefined) conOr = new facilitadorConecction();
    return conOr;
}