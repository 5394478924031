import { useParams } from "react-router-dom";
import { isLogin } from "../../../../Template/Seccion";
import Participante from "./Participante";
import Telefono from "./Telefono";
import { findProgramFila } from "@/Capacitacion/Context/Programas";


export default function Suscribirse() {
    const i = isLogin();
    const { id } = useParams<{ id: any }>();
    const p = findProgramFila(id);
    return i ? <Participante /> : <Telefono html={{}} id={p.id} />
}