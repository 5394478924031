import { Row, Container, Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getImagenURL } from '../Utils/Imagen';
import { getCSSCarruselInter, getSetting } from './CarruselCardText/styles';
import { nomURL, useFacilitador } from '@/Capacitacion/Context/Facilitadores';
import SliderLazy from '@/Utils/SliderLazy';
export default function Facilitadores({ data: { props } }: any) {
    const { color, inter } = props;
    const { facilitadores: f } = useFacilitador()
    const e = f.filter(e => e.estatus && e.estatus === 2);
    return <Row className='m-0' style={{ background: color, padding: '0 0 2rem 0' }}>
        <Container>
            {e.length === 1 ?
                <Itens key={`FacilitadorShort${nomURL(e[0].nombre)}`} iten={e[0]} props={props} /> :
                <SliderLazy settings={getSetting(e.length)} className={getCSSCarruselInter(inter)}>
                    {e.map(e => <Itens key={`FacilitadorShort${nomURL(e.nombre)}`} iten={e} props={props} />)}
                </SliderLazy>
            }
        </Container>
    </Row>
}
function Itens({ props: { gap, cards, text }, iten: { foto, nombre } }: any) {
    return <Col style={{ margin: '0 0 0 0', padding: '0 0 0 0' }}>
        <Card as={Link} to={`/f/${nomURL(nombre)}`} style={{ background: cards, margin: `0 ${gap}px 0 ${gap}px` }} className="c-content-person-1 c-option-2 mt-2">
            <Card.Img variant="top" src={getImagenURL(foto)} />
            <Card.Body style={{ color: text }}>
                <b>{nombre}</b>
            </Card.Body>
        </Card>
    </Col >
}
