import { useCallback, useEffect, useState } from "react";
import { timeFormat, timeFormatAtHora } from "@victorequena22/utiles";
import { Button } from "react-bootstrap";
import d, { } from 'dayjs'

export function Contador({ inicio, fin, url }: { inicio: string, fin: string, url: string }) {
    const [timer, setTimer] = useState(0);
    const [end, setEnd] = useState(0);
    const r = useCallback(() => {
        const a = getSegundos();
        if (timer >= 0) setTimer(get2(inicio) - a);
        if (end >= 0) setEnd(get2(fin) - a);
    }, [inicio, fin, end, timer]);
    useEffect(() => { setTimeout(() => r(), 1000); }, [r, end]);
    useEffect(() => { r(); }, [r, inicio, fin]);
    if (end >= 0) {
        if (timer >= 0) {
            if (timer < 3600) return <h2 className="text-center">Faltan:  <b>{timeFormat(timer, 0)}</b> para comenzar</h2>
            else return <h2 className="text-center">Faltan:  <b>{timeFormatAtHora(timer, 0)}</b> para comenzar</h2>
        } else if (url !== '') {
            return <Button size="lg" href={url} target="_blank" block as='a' variant='success'>Ir a la clase</Button>
        }
    }
    return <></>
}
function getSegundos(fecha?: string) {
    const date = d(fecha);
    const hora = parseInt(date.format('H'));
    const minuto = parseInt(date.format('m'));
    const segundo = parseInt(date.format('s'));
    return hora * 3600 + minuto * 60 + segundo;
}
function get2(time: string) {
    const h = time.split(':');
    const date = d().set('hour', parseInt(h[0])).set('minute', parseInt(h[1])).set('second', 0)
    const hora = parseInt(date.format('H'));
    const minuto = parseInt(date.format('m'));
    const segundo = parseInt(date.format('s'));
    return hora * 3600 + minuto * 60 + segundo;
}

