import { useContext } from "react";
import { AcordionData, DisplayData } from "../Data/DisplayData";
import { PropsDel } from "../Props";
import { SeccionContext } from "../../../../Template/Seccion";
import { ListaPagos } from "./Lista";
import { PrimerPago } from "./PrimerPago";
import { OtroPago } from "./OtroPago";
export function Pagos({ init, evento: e }: PropsDel) {
    const { data: { User: { pagos: p } }, user } = useContext(SeccionContext);
    const pagos = p ? p.filter(p => p.model_id === e.id && p.model === "evento") : [];
    if (pagos.length) return <>
        <AcordionData evento={e} />
        <ListaPagos pagos={pagos} /><br />
        <OtroPago init={() => { init(); user() }} evento={e} />
    </>
    if (e.estatus !== 1) return <AcordionData evento={e} />
    return <>
        <DisplayData evento={e} />
        <PrimerPago init={() => { init(); user() }} evento={e} />
    </>
}