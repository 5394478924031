export function zfill(number: number, width = 6) {
    let numberOutput = Math.abs(number) /* Valor absoluto del número */
    let length = number.toString().length /* Largo del número */
    let zero = "0" /* String de cero */

    if (width <= length) {
        if (number < 0) {
            return ("-" + numberOutput.toString())
        } else {
            return numberOutput.toString()
        }
    } else {
        if (number < 0) {
            return ("-" + (zero.repeat(width - length)) + numberOutput.toString())
        } else {
            return ((zero.repeat(width - length)) + numberOutput.toString())
        }
    }
}
export function numberFormat(number: number, decimal = 2) {
    return (number
        .toFixed(decimal) // always two decimal digits
        .replace('.', ',') // replace decimal point character with ,
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')) // use . as a separator
}
export function timeFormat(s2: number, decimal = 2) {
    const s = Math.floor(s2);
    const m = Math.floor(s / 60);
    return `${zfill(m, 2)}:${zfill(s - (m * 60), 2)}${(s2 - s).toFixed(decimal).replace('0', '')}`;
}
export function formatoFecha(fecha: string) {
    let date = new Date(fecha);
    let año = date.getFullYear();
    let mes = date.getMonth() + 1;
    let dia = date.getDate();
    return zfill(dia, 2) + '/' + zfill(mes, 2) + '/' + año;
};
export function formatoHora(fecha: string | undefined) {
    let date = fecha ? new Date(fecha) : new Date();
    let hora = date.getHours(),
        minuto = date.getMinutes(),
        segundo = date.getSeconds(),
        turno = ' AM';
    if (hora >= 12)
        turno = ' PM'
    if (hora > 12)
        hora = hora - 12;
    if (hora === 0)
        hora = hora + 12;
    return zfill(hora, 2) + ":" + zfill(minuto, 2) + ":" + zfill(segundo, 2) + turno;
};
export function solicitud() {
    let date = new Date();
    let año = date.getFullYear(),
        mes = date.getMonth() + 1,
        dia = date.getDate(),
        hora = date.getHours(),
        minuto = date.getMinutes(),
        segundo = date.getSeconds();
    return `${año}-${mes}-${dia}/${hora}:${minuto}:${segundo}`;
}
export function fechaToNumber(fecha?: string) {
    let date = fecha ? new Date(fecha) : new Date();
    let año = date.getFullYear(),
        mes = date.getMonth() + 1,
        dia = date.getDate(),
        hora = date.getHours(),
        minuto = date.getMinutes(),
        segundo = date.getSeconds();
    let str = año + zfill(mes, 2) + zfill(dia, 2) + zfill(hora, 2) + zfill(minuto, 2) + zfill(segundo, 2)
    return parseInt(str);
};


export function ToRGB(hex: string) {
    if (hex.charAt(0) === "#") hex = hex.substring(1, hex.length);
    const red = hex.substring(0, 2);
    const green = hex.substring(2, 4);
    const blue = hex.substring(4, 6);
    const alpha = hex.substring(6, 8);
    const r = parseInt(red, 16);
    const g = parseInt(green, 16);
    const b = parseInt(blue, 16);
    const a = parseInt(alpha, 16);
    return { r, g, b, a: isNaN(a) ? 255 : a };
}


const meses = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];

export function getMes(m: number) {
    return meses[m - 1];
}


export function busqueda(encontrar: any[], buscar: string = '') {
    if (buscar !== "") {
        const en = buscar.toUpperCase().split("-");
        const pa = encontrar.find(iten => {
            let considencias = 0, pasa = false;
            en.forEach(e => (stringToLink(iten.title).toUpperCase().indexOf(e) > -1) ? considencias++ : 0)
            if (en.length === considencias) pasa = true;
            return pasa;
        })
        if (pa) return pa;
        return { id: -1 }
    }
    return encontrar[0];
}

export function stringToLink(s: string) {
    return s.split(" ").map((st: string) => st.replace(/[^a-zA-Z0-9]/g, "")).join("-");
}

export function filtarPanels({ id, ocultar }: { id: number, ocultar: number[] }, p: any[]) {
    return p.filter(({ pagina, title, estado, id: id2 }) => pagina === id && title !== '' && estado === 1 && ocultar.every(i => i !== id2));
}
export function validarEmail(valor: string) {
    // eslint-disable-next-line no-useless-escape
    let validEmail = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
    return validEmail.test(valor);
}

export function focusId(id: string) {
    setTimeout(() => {
        const el = document.getElementById(id);
        if (el !== null) el.focus();
    }, 100)
}

export function MobileUserAgent() {
    if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i))
        return true;
    return false;
}

function getGradientTranfor(gradient: string, f: string, trans: (s: string) => string) {
    let a = f.replace(gradient + '-gradient(', '').replace('%)', '%')
        .split(' ').map(trans).join(', ');
    return `${gradient}-gradient(${a})`;

}
const t = (c: string) => {
    if (c.includes('rgba'))
        return toRGBAlphaRemove(c);
    if (c.includes('rgb'))
        return c.replace('),', '');
    return c;
}
export function removeAlfa(f: string) {
    if (f.includes('linear-gradient')) {
        return getGradientTranfor('linear', f, t);
    }
    if (f.includes('radial-gradient')) {
        return getGradientTranfor('radial', f, t);
    }
    return t(f);
}

export function toRGBAlphaRemove(rgba: string) {
    const [r, g, b] = rgba.replaceAll(' ', '').replaceAll('RGBA(', '').replaceAll('rgba(', '').replaceAll(')', '').split(',');
    return `rgb(${r},${g},${b})`;
}

