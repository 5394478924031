import { InputLabel, FormControl, Select, FormHelperText } from '@mui/material';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { CountryList, dataContry } from '../../Config/countrystatecity';
interface Props {
    change: (r: string) => void,
    defaultValue: string,
    width?: string
    error?: any;
    pais: string
    size?: "small"
}
export function StateSelector({ change, defaultValue, width = '100%', error, pais, size }: Props) {
    const set = useCallback((e: any) => change(e.currentTarget.value), [change]);
    const [dataList, setData] = useState<CountryList[]>([]);
    const cargar = useCallback(async () => {
        if (pais !== '') {
            const data = await dataContry().getCountryStates(pais);
            if (data && data !== null) setData(data);
        }
        // eslint-disable-next-line
    }, [dataList, pais])
    // eslint-disable-next-line
    useEffect(() => { cargar() }, [pais]);
    return useMemo(() => <FormControl error={error} style={{ width }} size={size}>
        <InputLabel style={{ backgroundColor: 'white', padding: '0 .25rem 0 .25rem' }} htmlFor='StateSelector'>Estado*</InputLabel>
        <Select native value={defaultValue} onChange={set} label="Pais" inputProps={{ id: 'StateSelector' }}>
            {dataList.map(p => <option key={p.iso2} value={p.iso2}>{p.name}</option>)}
            <option aria-label="" value="" />
        </Select>
        {error ? <FormHelperText id={`ContrySelector-error`}>{error}</FormHelperText> : <></>}
    </FormControl>, [error, width, size, defaultValue, set, dataList]);
}