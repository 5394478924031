import { don } from "@victorequena22/conneccion";

export const post = (url = '', obj = {}, success = (_d: any) => { }) => {
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'json';
    xhr.open("POST", `${don}/${url}`);
    xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) {
            return;
        }
        if (xhr.status === 200) {
            success(xhr.response);
        }
    };
    xhr.send(FormDataFotmat(obj));
}
const FormDataFotmat = (o: any) => {
    const formData = new FormData();
    Object.keys(o).forEach(key => {
        switch (typeof o[key]) {
            case 'object':
                formData.append(key, JSON.stringify(o[key]));
                break;
            default:
                formData.append(key, o[key]);
        }
    });
    return formData;
};