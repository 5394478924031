/* eslint-disable jsx-a11y/anchor-is-valid */
import ModalLogin from "../../../Participante/Modales/ModalLogin";
import { DisplayData } from "./DisplayData";
import { Evento } from "../../Models";
import { Col } from "react-bootstrap";
import { useState } from "react";

export function Registrarse({ evento }: { evento: Evento }) {
    const [open, setOpen] = useState(false);
    return <>
        <DisplayData evento={evento} />
        <Col md={12} className="mt-2D">
            <ModalLogin open={open} close={() => setOpen(false)} />
            <hr />
            <a className="text-primary text-center" onClick={() => setOpen(true)}  >
                <h2><b>
                    Por favor inicie sesión para inscribirse en este evento.<br />
                    Si aún no ha creado su usuario, regístrese aquí
                </b></h2>
            </a>
        </Col>
    </>
}