import { don } from "@victorequena22/conneccion";
import { MobileUserAgent } from "./Formatos";
export async function localImage(src: string, set: (s: string) => void) {
    set(src);
}
export function getLocalImage(src: string) {
    const n64 = localStorage.getItem(src);
    if (n64 === null) return src;
    return n64;
}
export function valLocalImage(src: string) {
    const n64 = localStorage.getItem(src);
    if (n64 === null) return false;
    return true;
}
export const createImage = (url: string) => new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image as HTMLImageElement))
    image.addEventListener('error', () => reject(null))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
})
export async function getV(src: string, set: (s: string) => void) {
    const { width, height } = await createImage(src);
    set((height / width) > .5 ? 'h' : 'w');
}
export function getImagenURL(src?: string, _d?: number) {
    if (src && src.indexOf('/api/Media/ver/') > -1) {
        const n = src.replaceAll('api/Media/ver', '').replaceAll('/', '');
        const d = don.slice(-1) === '/' ? don.slice(0, -1) : don;
        return `${d}/api/Media/${n}/${MobileUserAgent() ? 'movil' : 'full'}.webp`;
    }
    return `${don}/${src}`;
}
