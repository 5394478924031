import { useState, useEffect } from "react";
import { Button, FormCheck } from "react-bootstrap";
import { conEvento } from "../Models";
import { DisplayData, AcordionData } from "./Data/DisplayData";
import { PropsDel } from "./Props";
import { Encuesta } from "./Encuesta";
import { useSeccion } from "@/Template/Seccion";
export function Inscribirse({ init, evento, participante }: PropsDel) {
    const { user } = useSeccion()
    const [v, setv] = useState(false);//validacion Aceptar condiciones 
    const [a, setA] = useState(false);//Aceptar condiciones
    const [r, setR] = useState(false);//validacion de respuesta de la encuesta
    const [respuesta, setRespuesta] = useState('');//respuesta de la encuesta
    useEffect(() => { setv(false); setR(false) }, [respuesta, a])
    return participante ? <AcordionData evento={evento} /> : <>
        <DisplayData evento={evento} />
        <p className="ml-3 mr-3">
            <FormCheck checked={a} isInvalid={v} type="checkbox" onClick={() => setA(!a)}
                feedback="Para poder inscribirse debe aceptar las normas y consideraciones"
                label='Acepto las normas y consideraciones'
            /><br />
            {a ? <Encuesta respuesta={respuesta} setRespuesta={setRespuesta} isError={r} /> : <></>}
        </p>
        <Button block variant='success' className='m-auto' size="lg"
            onClick={() => {
                if (a && respuesta !== '') {
                    const con = conEvento();
                    con.inscribirse(evento.id, init);
                    con.encuesta(evento.id, respuesta, init);
                    setTimeout(() => user(), 100);
                } else {
                    if (!a) setv(true)
                    if (respuesta === '') setR(true)
                }
            }} >
            Inscribirse
        </Button>
    </>
}