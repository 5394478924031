import { useCallback, useEffect, useState } from "react";
import { Button, FormGroup, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import InputFild from "../../../Components/Material/InputFild";
import { ConConfig } from "../../../Config/Config";
import { don } from "@victorequena22/conneccion";


export default function Recuperar({ close }: { close: () => void }) {
    const [usuario, setUsuario] = useState('');
    const [error, setError] = useState<any>({});
    const send = useCallback(() => {
        ConConfig('').post(don + '/api/Participante/recuperar', { usuario }, () => {
            toast.success('MESAJE ENVIADO');
            close();
        }, e => setErr(e, setError))
    }, [close, usuario]);
    useEffect(() => setError({}), [usuario])
    return <>
        <FormGroup>
            <InputFild error={error.usuario} maxLength={200} setData={setUsuario} label="Usuario o correo" value={usuario} />
        </FormGroup>
        <FormGroup><Row as={Col}>
            <Button className="ml-auto" onClick={() => send()}
                variant="outline-info">EVIAR MENSAJE</Button>
        </Row></FormGroup>
    </>;
}
const setErr = (e: any, setError: (e: any) => void) => {
    const error: any = {};
    e.forEach((e: any) => {
        if (e.sql) e.sql.forEach((er: string) => toast.error(er));
        else Object.keys(e).forEach(t => error[t] = e[t])
    })
    setError(error);
}