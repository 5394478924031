import { Row, Container, Card, Image } from 'react-bootstrap';
import { getImagenURL } from '../Utils/Imagen';
import './BannerImagen.css';
import SliderLazy from '@/Utils/SliderLazy';
import { getCSSCarruselInter } from './CarruselCardText/styles';
export default function BannerImagen({ data: { itens, props } }: any) {
    const { color, inter, up, down } = props;
    const { length: l } = itens;
    const settings = {
        autoplaySpeed: 5000,
        slidesToShow: 3 > l ? l : 3,
        responsive: [{ breakpoint: 570, settings: { slidesToShow: 2 > l ? l : 2 } }]
    }
    return <Row className='p-0 m-0'>
        <div className="w-100 p-0 imagen-banner-bottom" style={{ background: up }} />
        <Container className='p-0 w-100'>
            <Card className={`imagen-banner m-0 w-100`} style={{ background: color, padding: '.4rem 3rem .4rem 3rem' }}>
                <SliderLazy className={getCSSCarruselInter(inter)} settings={settings}>
                    {itens.map(({ props: i, id }: any) => <div key={`BannerImagenDiv${id}`}>
                        <Image key={`BannerImagen${id}`} className={`m-auto`} src={getImagenURL(i.img)} alt={`banner ${id}`} />
                    </div>)}
                </SliderLazy>
            </Card>
        </Container>
        <div className="w-100 p-0 imagen-banner-top" style={{ background: down }} />
    </Row>
}