/* eslint-disable react-hooks/rules-of-hooks */
import { createContext, useContext, useEffect } from 'react';
// import { Tags } from '../../Media/Models';
import { facilitadores as f } from './Data';
import { conFacilitador, FacilitadorFila, facilitadorNull } from '../Facilitadores/Models';
import { useLista } from '@/Utils/useActualizador';

export interface FacilitadoresType {
    facilitadores: FacilitadorFila[]
    actualizar: () => void
}
export const FacilitadoresContext = createContext<FacilitadoresType>({
    facilitadores: f, actualizar: () => { }
});
export default function FacilitadoresProvider({ children }: { children: any }) {
    const [facilitadores, actualizar] = useLista<FacilitadorFila>(conFacilitador(), f);
    // eslint-disable-next-line
    useEffect(() => { actualizar(); }, []);
    return <FacilitadoresContext.Provider value={{ facilitadores, actualizar }}>
        {children}
    </FacilitadoresContext.Provider>
}
export const SeccionConsumer = FacilitadoresContext.Consumer;
export function findFacilitador(id: number | string): FacilitadorFila {
    const { facilitadores } = useContext(FacilitadoresContext);
    const cat = facilitadores.find(p => p.id === id || nomURL(p.nombre) === id);
    return cat ? cat : facilitadorNull;
}

export function nomURL(texto: string) {
    return texto.trimEnd().trimStart().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replaceAll(' ', "-");
}
export function useFacilitador() {
    return useContext(FacilitadoresContext);
}