import { css } from '@emotion/css';
import { Container, FormGroup, Button } from 'react-bootstrap';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Invert } from '../Components/Invert';
import Input, { Correo, Telefono, valCorreo } from '../Utils/Input';
import { post } from '../Utils/Post';
import './Contact.css';
export default function Contact({ data }: { data: any }) {
    const { props: { padding, input, inputText, button, buttonText, backgroundColor } } = data;
    const [nombre, setNombre] = useState('');
    const [correo, setCorreo] = useState('');
    const [asunto, setAsunto] = useState('');
    const [telefono, setTelefono] = useState('');
    const [mensaje, setMSJ] = useState('');
    const [valid, setValit] = useState(false);
    const [sus, setSus] = useState(false);
    const suscribirse = () => {
        if (val(nombre, correo, asunto, telefono, mensaje))
            post('/api/Web/Contacto/nuevo', { nombre, correo, asunto, telefono, mensaje }, () => {
                toast.success('GRACIAS POR CONTACTARME');
                setSus(true);
            }); else setValit(true)
    }
    return <div style={{ paddingBlock: padding, background: backgroundColor }}>
        <Container>
            <Invert data={data}>
                {sus ? <div className={`gracias`}><Button block className={css`${toCSS(button, buttonText)}`} size='lg'>
                    Gracias por suscribirte
                </Button></div> : <FormGroup className={`contact-panel ${getCSS(input, inputText, button, buttonText)}`}>
                    <div style={{ gridArea: 'z' }}>
                        <Input value={nombre} setData={setNombre} placeholder="Ingresa tu nombre" valid={valid}
                            title={!valid ? "Ingresa tu nombre" : "Debe colocar el nombre"} name='name'
                            next={() => { document.getElementById('correo')?.focus() }} style={{ gridArea: 'a' }} />
                        <Correo value={correo} setData={setCorreo} placeholder="Ingresa tu correo" id='correo' valid={valid}
                            title={!valid ? "Ingresa tu correo" : "Debe colocar el correo"} name='email'
                            next={() => { document.getElementById('asunto')?.focus() }} style={{ gridArea: 'b' }} />
                        <Input value={asunto} setData={setAsunto} placeholder="Ingresa el asunto" id='asunto' valid={valid}
                            title={!valid ? "Ingresa el asunto" : "Debe colocar el asunto"} name='issue'
                            next={() => { document.getElementById('telefono')?.focus() }} style={{ gridArea: 'c' }} />
                        <Telefono value={telefono} setData={setTelefono} placeholder="Ingresa su telefono" id='telefono' valid={valid}
                            title={!valid ? "Ingresa su telefono" : "Debe colocar el telefono"} name='phone'
                            next={() => { document.getElementById('msj')?.focus() }} style={{ gridArea: 'd' }} />
                        <Input value={mensaje} setData={setMSJ} as='textarea' id='msj' next={suscribirse} valid={valid}
                            title={!valid ? "Por favor, escriba su mensaje aqui" : "Debe colocar el mensaje"} name='msn'
                            placeholder="Por favor, escriba su mensaje aqui" style={{ gridArea: 'e' }} />
                    </div>
                    <Button onClick={suscribirse} className='btn-block' size='lg' style={{ gridArea: 'x' }}>Subscribirse</Button>
                </FormGroup>}
            </Invert>
        </Container>
    </div>
}
function getCSS(color1: string, color2: string, color3: string, color4: string) {
    return css`
    &>div{ 
        background: ${color2} !important;
        border-color: ${color2} !important;
        &>input,&>textarea{${toCSS(color1, color2)}}
    }
    &>button{${toCSS(color3, color4)}}`;
}
function toCSS(back: string, text: string) {
    return `
    background: ${back} !important;
    color: ${text} !important;
    &::placeholder{
        color: ${text} !important;

    }`
}
function val(nombre: string, correo: string, asunto: string, telefono: string, msj: string) {
    // eslint-disable-next-line
    const t = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(telefono);
    return (nombre !== '') && (
        (correo !== '' && valCorreo(correo)) ||
        ((telefono !== '') && t)
    ) && (msj !== '') && (asunto !== '');
}