import { Dayjs } from 'dayjs';
import 'dayjs/locale/es';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DatePicker } from '@mui/x-date-pickers';
import { MobileUserAgent } from '../../../Utils/Formatos';
export interface Props {
    label: string
    error?: any;
    setData: (data: any) => void,
    value: string
    update?: string
}

export default function MaterialUIPickers({ setData, value, label, update }: Props) {
    // const isError = !!(update ? error[update] : error);
    const handle = (v: Dayjs) => {
        const v2 = v.format('YYYY-MM-DD');
        if (update) setData({ [update]: v2 })
        else setData(v2)
    }
    return (<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='es'>
        <Stack spacing={3}>
            {MobileUserAgent() ?
                <MobileDatePicker label={label} value={value} format="DD/MM/YYYY" onChange={handle as any} /> :
                <DatePicker label={label} value={value} format="DD/MM/YYYY" onChange={handle as any} />}
        </Stack>
    </LocalizationProvider>);
}