import { Navbar, Nav, Container } from 'react-bootstrap';
import { css } from '@emotion/css';
import { menu } from '../Data';
import { Html } from '../Components/Html';
import User from '../Capacitacion/Participante/Autenticacion/User';
import { Link } from 'react-router-dom';
import { getImagenURL } from '../Utils/Imagen';
import "./Menu.css";
import { don } from '@victorequena22/conneccion';
import EventoMenuTemplate from '@/Capacitacion/Evento/EventoMenuTemplate';
import ProgramaMenuTemplate from '@/Capacitacion/Programas/ProgramaMenuTemplate';

export default function Menu() {
    const ts = ((new URL(don)).hostname === 'talentosoftware.net') || ((new URL(don)).hostname === 'landing.talentosoftware.net');
    const { height, html, orientacion, backgroundColor, color, backgroundColorHover, colorHover, img } = menu;
    return <>
        <Navbar fixed='top' expand="lg" className={getCSS(backgroundColor, color, backgroundColorHover, colorHover)}>
            <Container>
                <Link to='/' className='title d-flex mr-auto'>
                    <img height={height + 'px'} width='content-fit'
                        className="img-responsive" src={getImagenURL(img)} alt="inicio" />
                    <Html html={html} />
                </Link>
                {ts ? <></> : <>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className={`m${orientacion ? 'l' : 'r'}-auto`}>
                            <Link to='/' className='dropdown-item'>
                                <b>Inicio</b>
                            </Link>
                            <ProgramaMenuTemplate />
                            <EventoMenuTemplate />
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className='ml-auto' />
                    <User />
                </>}
            </Container>
        </Navbar>
        <div style={{ height: `calc(${height}px + 1rem)` }} />
    </>
}
export function getCSS(backgroundColor: string, color: string, backgroundColorHover: string, colorHover: string) {
    const a = `&> a {
        color: ${color} !important;
        &:hover {
            background: ${backgroundColorHover} !important;
            color: ${colorHover} !important;
        }
    }`;
    const toggler = (color: string) => `&> .navbar-toggler-icon{
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='${colorFormat(color)}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }`;
    return css`
        background: ${backgroundColor};
        max-width: 100vw;
        & > * {height: 100%;}
        & > .container{
            ${a}
            &> button {
                color: ${color} !important;
                border-color: ${color} !important;
                ${toggler(color)}
                &:hover {
                    background: ${backgroundColorHover} !important;
                    color: ${colorHover} !important;
                    border-color: ${colorHover} !important;
                    ${toggler(color)}
                }
            }
            &> .dropdown, &> .navbar-collapse> .navbar-nav> .dropdown{
                ${a}
                &> .dropdown-menu{
                    margin-top: -5px;
                    background: ${backgroundColor} !important;
                    border: none;
                    ${a}
                }
                &:hover> .dropdown-menu{ display: block; }
            }
            &> .title {
                background: #0000 !important;
                &:hover {
                    background: #0000 !important;
                }
            }
        }
        &> .container> .navbar-collapse> .navbar-nav{${a}}
    `
}
const colorFormat = (color: string) => {
    let c: any;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
        c = color.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
    }
    return color;
}