import { Card, Col } from "react-bootstrap";
import { css } from '@emotion/css';
import { isLogin } from "../../../Template/Seccion";
import  "./susb.css";
export default function Suscribirse({ html, config, Participante, Telefono }: { html: any, config: any, Participante: any, Telefono: any }) {
    const i = isLogin();
    const { online = false } = config;
    return <Col xs={12} md={5} xl={4} className="p-xs p-sm  p-all">
        <Card className={`${getCSS(config)} subcripcion p-0 m-0`} id='Suscribir'>
            {i ? <Participante html={html} /> : <Telefono online={online} html={html} />}
        </Card>
    </Col>
}

export function getCSS({ colorFF = '#ffffff', colorFC = '#ffffff', colorBC = '#0069d9', colorTC = '#000000', colorBT = '#ffffff', fontC = 'Arial', fontB = 'Arial' }: any) {
    return css`
    background: ${colorFF};
    &>.row{
        &>.input{
            &>.MuiFormControl-root{
                &>.MuiInputBase-root{
                    &>.MuiOutlinedInput-notchedOutline{
                        border-color: ${colorTC} !important;
                    }
                    &>fieldset{
                        background: ${colorFC} !important;
                    }
                    &>input,
                    &>select,
                    &>select>option{
                        color: ${colorTC} !important;
                        font-family: ${fontC} !important;
                        border-color: ${colorTC} !important;
                    }
                }
            }
            &>.MuiBox-root{
                background: ${colorFC} !important;
                border: ${colorTC} solid 1px !important;
                &>span{
                    color: ${colorTC} !important;
                    font-family: ${fontC} !important;
                }
                &>.MuiFormControl-root{
                    &>.MuiInputBase-root{
                        &>.MuiOutlinedInput-notchedOutline{
                            border-color: ${colorTC} !important;
                        }
                        &>input,
                        &>select,
                        &>select>option{
                            color: ${colorTC} !important;
                            font-family: ${fontC} !important;
                        }
                    }
                    &>.MuiFormLabel-root{
                        background: ${colorFC} !important;
                    }
            }
        }
        &>.text{
            color: ${colorTC} !important;
            font-family: ${fontC} !important;
        }
        &>.boton>.btn {
                background: ${colorBC};
                border-color: ${colorBC};
                color: ${colorBT};
                font-family: ${fontB} !important;
            }
        }
    }`;
}


