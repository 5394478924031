import { toast } from "react-toastify";
import { don } from "../../../Config/Coneccion";
import { SeccionContext } from "../../../Template/Seccion";
import { conParticipante, ParticipanteNull } from "../Models";
import { Prototipo } from "./From";
interface Props {
    accion?: () => void
}
class Nuevo extends Prototipo<Props>{
    context: any;
    constructor(p: any) {
        super(p)
        this.context = {};
        this.state = { ...ParticipanteNull, confirmarClave: '', error: {} };
    }
    save = () => {
        if (this.validar()) {
            if (this.procesar) {
                this.procesar = false;
                conParticipante().nuevo(this.getData(), this.success, this.setError)
            }
        } else this.forceUpdate();
    }
    success = () => {
        this.procesar = true;
        this.send();
        toast.success('Registrado');
    }
    send = () => {
        const { usuario, clave } = this.getData()
        conParticipante().post(don + '/api/Participante/login', { usuario, clave }, (data) => {
            localStorage.setItem('Authorization', data.token);
            this.context.user();
        })
    }
}
Nuevo.contextType = SeccionContext;
export default Nuevo;