import { Col, Image, Row, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { findProgram } from "../../Context/Programas";
import { Cargando } from "@victorequena22/component-bootstrap";
import { Html } from "../../../Components/Html";
import { findCategoria } from "../../Context/Categorias";
import { Capacitacion } from "../Models";
import Suscribirse from "./Suscribirse";
import { getImagenURL } from "../../../Utils/Imagen";

export default function ProgramaVer() {
    const { id } = useParams<{ id: any }>();
    const p = findProgram(id) as Capacitacion;
    // const tags = findTags(p.tags)
    if (p.id === 0 || p.resumen === undefined) return <Cargando />
    return <Row className='p-0 m-0'>
        <Container>
            <Row className="p-2">
                <Col md={12} className='m-2'>
                    <h1 className='w-100 text-primary'><b>{p.nombre}</b></h1>
                </Col>
                <Col md={4}>
                    <Image fluid src={getImagenURL(p.short)} alt={`programa ${id}`}/>
                </Col>
                <Col md={8}>
                    <Col md={12}><b>Categoría: </b>{findCategoria(p.categoria).nombre}</Col>
                    <Col md={12}><b>Descripcion: </b>{p.descripcion}</Col>
                    <Col md={12}><b>Horas académicas : </b>{p.horas}</Col>
                    {valText(p.contenido) ? <Col md={12}>
                        <h3 className='w-100 text-info'><b>Contenidos</b></h3>
                        <Html html={p.contenido} />
                    </Col> : <></>}
                    {/* <Col md={12}>
                        <p><b>Etiquetas: </b></p>
                        <Row className='p-2 m-0'>
                            {tags.map(t => <span key={t.nombre} className='m-1'>{t.nombre}</span>)}
                        </Row>
                    </Col> */}
                </Col>
                <Col md={12}>
                    <h3 className='w-100 text-info'><b>Resumen</b></h3>
                    <Html html={p.resumen} />
                </Col>
                <Col md={12}><Suscribirse /></Col>
            </Row>
        </Container>
    </Row>

}

function valText(t: { children: { text: string }[] }[]) {
    if (t.length === 0) return false;
    if (t[0].children.length === 0) return false;
    if (t[0].children[0].text === '') return false;
    return true;
}