import { useCallback, useEffect, useMemo, useState } from "react";
import { ConexionCompleta, setCambio } from "@victorequena22/conneccion";
import { conLanding } from "@/Capacitacion/Landing/Landing";
import { data } from "@/Data";
import { useSemaforo } from "./ReactUtils";

type respueta<p> = [p, () => void];

export function useCambios<p>(c: ConexionCompleta<any, p>, d: p[] = [], trans: (a: any[]) => p[] = p => p): respueta<p[]> {
    const [con] = useState(c);
    const [pa, setPa] = useState<p[]>(d);
    const actualizar = useCallback(async () => await con.cambios(data => setPa(setCambio(trans(data), pa))), [con, trans, pa]);
    useSemaforo(con.clase + 'cambio', actualizar)
    // eslint-disable-next-line 
    useEffect(() => { actualizar() }, []);
    return [pa, actualizar];
}
export function useLista<p>(c: ConexionCompleta<any, p>, d: p[] = []): respueta<p[]> {
    const [pa, setPa] = useState<p[]>(d);
    const actualizar = useCallback(() => c.lista(data => setPa(setCambio(data, pa))), [c, pa]);
    return [pa, actualizar];
}
export function useDetalleCambios<p>(id: string | number, c: ConexionCompleta<any, p>, d: p): respueta<p> {
    const [pa, setPa] = useState<p>(d);
    const actualizar = useCallback(async () => await c.detalle(id, setPa), [id, c]);
    useSemaforo(c.clase + 'detalle', actualizar)
    // eslint-disable-next-line 
    useEffect(() => { actualizar() }, []);
    return [pa, actualizar];
}
export function useDetalle<p>(id: string | number, c: ConexionCompleta<any, p>, d: p): respueta<p> {
    const [pa, setPa] = useState<p>(d);
    const actualizar = useCallback(() => c.detalle(id, setPa), [id, c]);
    return [pa, actualizar];
}
export function useLanding(model: string, id: string | number): [any[], boolean, () => void] {
    const [con] = useState(conLanding());
    const [panels, setPanels] = useState(getData(model, id));
    const [cargando, setCargando] = useState(!panels.length);
    const actualizar = useCallback(async () => {
        await con.cambios2(model, id, data => setPanels(setCambio(data, panels)));
        setCargando(false)
    }, [con, model, id, panels]);
    useSemaforo(model + id, actualizar);
    // eslint-disable-next-line 
    useEffect(() => { actualizar() }, []);
    return [usePanels(panels), cargando, actualizar];
}
const getData = (model: string, id: string | number) => {
    if (data[model] && data[model].id === id) {
        return data.landing;
    }
    return []
}
const usePanels = (panels: any[]) => {
    return useMemo(() => panels.filter(p => p.indice > 0 && p.estado > 0).sort((a, b) => a.indice - b.indice), [panels]);
}
