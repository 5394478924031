/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { conEvento } from "../../Models/Coneccion";
import { Costo, costoNull } from "../../Models/Model";
export default function Avales({ ids }: { ids: number[] }) {
    return <ul>
        {ids.map(id => <li key={`avales${id}`}>
            <Aval key={'aval' + id} id={id} />
        </li>)}
    </ul>
}
export function Aval({ id }: { id: number }) {
    const [a, seta] = useState<Costo>(costoNull);
    useEffect(() => { if (id !== 0) conEvento().aval(id, seta); }, [id])
    return <>{a.nombre}</>;
} 