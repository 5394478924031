import { BrowserRouter as Router, Switch } from "react-router-dom";
import { SeccionProvider } from '@/Template/Seccion.tsx';
import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import App from './App.tsx'
import './index.css'
createRoot(document.getElementById('root')!).render(<StrictMode>
  <SeccionProvider><Router>
    <Switch><App /></Switch>
  </Router></SeccionProvider>
</StrictMode>)
