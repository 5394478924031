import CP from "./Categorias";
import EP from "./Eventos";
import MPP from "./MetodosPago";
import PP from "./Programas";
import TP from "./Tags";



export default function ContextProvider({ children }: { children: any }) {
    return <CP><TP><EP><PP><MPP>
        {children}
    </MPP></PP></EP></TP></CP>
}