import { THFila, THInput } from "@victorequena22/tabla-bootstrap";
import { Button, Col, Table } from "react-bootstrap";
import d from 'dayjs'
import { CSSProperties, useCallback, useEffect, useState } from "react";
import { Asistencia as Model, conAsistencia } from "../../Models";
import { Link } from "react-router-dom";
import { formatoHora } from "@/Utils/Formatos";


const t: CSSProperties = { width: 150, color: '#FFF', textAlign: 'center', fontWeight: 'bold' }
export default function Asistencia({ id }: { id: number; }) {
    const [asistencias, setAsistencias] = useState<Model[]>([]);
    const actualizar = useCallback(async () => await conAsistencia().eventoLista(id, setAsistencias), [id])
    useEffect(() => {
        const interval = setInterval(actualizar, 1000);
        return () => clearInterval(interval);
    }, [actualizar])
    useEffect(() => { actualizar() }, [])
    return <>
        <Col lg={12}><h2 className="text-info"><b>Sesiones</b></h2></Col>
        <Table striped hover>
            <thead className="bg-info">
                <tr key="header">
                    <th style={t}>Fecha</th>
                    <th style={t}>Hora de inicio</th>
                    <th style={t}>Hora de finalización</th>
                    <th style={{ ...t, width: 90 }}>Estatus</th>
                    <th style={t}></th>
                </tr>
            </thead>
            <tbody>
                {asistencias.map(a => <tr key={a.actualizado}>
                    <THFila align="center" width={150}>{d(a.fecha).format('DD/MM/YYYY')}</THFila>
                    <THFila align="center" width={150}>{formatoHora(`${a.fecha}T${a.inicio}`)}</THFila>
                    <THFila align="center" width={150}>{formatoHora(`${a.fecha}T${a.final}`)}</THFila>
                    <THFila align="center" width={90}><Status s={a.asistentes} /></THFila>
                    <THInput align="center" width={40}>
                        <Button as={Link} to={`/evento/${a.codigo}/asistencia`} variant="info">
                            <i className="fas fa-eye" />
                        </Button>
                    </THInput>
                </tr>)}
            </tbody>
        </Table>
    </>
}
function Status({ s }: { s: number }) {
    const span = ['danger', 'info', 'success']
    const text = ['Reprobo', 'Asistió ', 'Aprobo']
    return s !== -1 ? <span className={`badge badge-${span[s]}`}>{text[s]}</span> : <span className={`badge badge-warning`}>No Asistió</span>
}