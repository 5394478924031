/* eslint-disable no-new-func */
// const page: any[] = Function('return page.paginas')();
const menu: any = Function('return menu')();
const chats: any = Function('return chats')();
const empresa: any = Function('return empresa')();
const footer: any = Function('return footer')();
const panels: any[] = Function('return panels')();
const screen = Function('return screen')() as Screen;
const data = Function('return data ? data : []')() as any;
const url = Function('return url')() as string;
export { menu, chats, empresa, footer, panels, screen, data, url };