import { useParams } from "react-router-dom";
import { conCapacitacion } from "../../Models";
import { Button } from "react-bootstrap";
import { suscrito, user } from "../../../../Template/Seccion";
import { programToId } from "../../../Context/Programas";

export default function Participante() {
    const id = programToId(useParams<{ id: any }>().id);
    const s = suscrito(id);
    return s ? <h3 className="text-success">
        Ya estas suscrito
    </h3> : <Button block variant="primary" onClick={() => {
        conCapacitacion().Suscribirse(id, user)
    }}>Suscribirse</Button>
}