import { empresa } from "../Data";


export const updateHeader = (title: string, descripcion: string, imagen: string) => {
    const t1 = getEle('title1');
    if (t1) t1.innerHTML = title
    const t2 = getEle('title2');
    if (t2) t2.setAttribute('content', title);
    const t3 = getEle('title3');
    if (t3) t3.setAttribute('content', title);
    if (descripcion !== '') {
        const d1 = getEle('descripcion1');
        if (d1) d1.setAttribute('content', descripcion)
        const d2 = getEle('descripcion2');
        if (d2) d2.setAttribute('content', descripcion)
    }
    if (imagen !== '') {
        const i = imagen.replace('ver', 'short')
        const i1 = getEle('image1');
        if (i1) i1.setAttribute('content', i)
        const i2 = getEle('image2');
        if (i2) i2.setAttribute('content', i)
    }
}
function getEle(id: string) {
    return document.getElementById(id)
}
export const defaultHeader = () => {
    const title = empresa.nombre;
    const descripcion = empresa.eslogan;
    const imagen = '/public/imagenes/' + empresa.logo;
    const t1 = getEle('title1');
    if (t1) t1.innerHTML = title
    const t2 = getEle('title2');
    if (t2) t2.setAttribute('content', title);
    const t3 = getEle('title3');
    if (t3) t3.setAttribute('content', title);
    if (descripcion !== '') {
        const d1 = getEle('descripcion1');
        if (d1) d1.setAttribute('content', descripcion)
        const d2 = getEle('descripcion2');
        if (d2) d2.setAttribute('content', descripcion)
    }
    if (imagen !== '') {
        const i1 = getEle('image1');
        if (i1) i1.setAttribute('content', imagen)
        const i2 = getEle('image2');
        if (i2) i2.setAttribute('content', imagen)
    }
}