import { useParams } from "react-router-dom";
import { findEventoFila } from "../../../../Context/Eventos";
import { conCapacitacion } from "../../../../Programas/Models";
import Telefono2 from "../../../../Paneles/Suscribirse/Telefono";
class Tel2 extends Telefono2 {
    send = () => {
        const { pais, correo, estado, ciudad, telefono, phone, nombre } = this.state;
        const { id } = this.props;
        if (this.getValidation())
            conCapacitacion().evento(id, { pais, correo, estado, ciudad, telefono:`+${phone}-${telefono}`, nombre },
                () => this.setState({ suscrito: true }), error => this.setState({ error }))
    }
}
export default function Telefono({ html }: { html: any }) {
    const { id, pais } = findEventoFila(useParams<{ id: any }>().id);
    return <Tel2 id={id} online={pais !== 'ONLI'} html={html} />
}