/* eslint-disable react-hooks/rules-of-hooks */
import { createContext, useContext, useEffect, } from 'react';
// import { Tags } from '../../Media/Models';
import { Categoria, conCategoria, categoriaNull } from '../Programas/Categorias/Models';
import { categorias } from './Data';
import { useLista } from '@/Utils/useActualizador';

export interface CategoriasType {
    Categorias: Categoria[]
    actualizarCategoria: () => void
}
export const CategoriasContext = createContext<CategoriasType>({
    Categorias: categorias, actualizarCategoria: () => { }
});
export default function CategoriasProvider({ children }: { children: any }) {
    const [Categorias, actualizarCategoria] = useLista<Categoria>(conCategoria(), categorias);
    // eslint-disable-next-line
    useEffect(() => { actualizarCategoria(); }, []);
    return <CategoriasContext.Provider value={{ Categorias, actualizarCategoria }}>
        {children}
    </CategoriasContext.Provider>
}
export const SeccionConsumer = CategoriasContext.Consumer;
export function findCategoria(id: number | string): Categoria {
    const { Categorias } = useContext(CategoriasContext);
    const cat = Categorias.find(p => p.id === id || p.codigo === id);
    return cat ? cat : categoriaNull;
}