import { Table } from "react-bootstrap";
import { Pago } from "../../../Pagos/Models";
import { FilaPago } from "./Fila";

export function ListaPagos({ pagos }: { pagos: Pago[] }) {
    return <Table>
        <tr>
            <th>Fecha</th>
            <th>Banco</th>
            <th>Referencia</th>
            <th>Monto</th>
            <th>estatus</th>
            <th>Nota</th>
        </tr>
        {pagos.map(p => <FilaPago pago={p} key={`pagos${p.id}${p.estatus}`} />)}
    </Table>
}