/* eslint-disable react-hooks/rules-of-hooks */
import { useState, createContext, useEffect, useContext } from 'react'
import { conEvento, arrayToEvento } from '../Evento/Models';
import { EventoLista, eventoNull } from '../Evento/Models/Model';
import { eventos as e } from './Data'
import { data } from '@/Data';
import { useCambios } from '@/Utils/useActualizador';

export interface EventosType {
    eventos: EventoLista[]
    actualizar: () => void
}
export const EventoContext = createContext<EventosType>({ eventos: e, actualizar: () => { } });
export default function EventosProvider({ children }: { children: any }) {
    const [eventos, actualizar] = useCambios<EventoLista>(conEvento(), e, arrayToEvento);
    return <EventoContext.Provider value={{ eventos, actualizar }}>
        {children}
    </EventoContext.Provider>
}
export const SeccionConsumer = EventoContext.Consumer;

export function findEvento(id: number | string, def = eventoNull): EventoLista {
    const [evento, setEvento] = useState<any>(getEvento(id));
    useEffect(() => { conEvento().detalle(id, setEvento) }, [id]);
    return evento ? evento : def;
}
export function findEventoFila(id: number | string): EventoLista {
    const [evento] = useState(getEvento(id));
    return evento;
}
function getEvento(id: number | string): EventoLista {
    if (data.evento && (data.evento.id === id || data.evento.codigo === id)) {
        return data.evento;
    }
    const { eventos } = useContext(EventoContext);
    const evento = eventos.find(p => p.id === id || p.codigo === id);
    return evento ? evento : eventoNull;
}