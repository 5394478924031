/* eslint-disable react-hooks/rules-of-hooks */
import { useState, createContext } from 'react';

export interface ChatType {
    text: string
    setText: (text: string) => void
}
export const ChatContext = createContext<ChatType>({ text: '', setText: (_text: string) => { } })
export var user = () => { };
export function ChatProvider({ children }: { children: any }) {
    const [text, setText] = useState('');
    return <ChatContext.Provider value={{ text, setText }}>{children}</ChatContext.Provider>
}
export const ChatConsumer = ChatContext.Consumer;
