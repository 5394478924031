import { useContext } from "react";
import { Card, Row } from "react-bootstrap";
// import { Button, ButtonGroup, Card, Col, FormControl, InputGroup, Row } from "react-bootstrap";
import { ProgramaContext } from "../../Context/Programas";
import { Link } from "react-router-dom";
import { getImagenURL } from "../../../Utils/Imagen";
import { data } from "./Data";

export default function VerProgramas() {
    const { backCard, TextCard } = data;
    const { programas } = useContext(ProgramaContext);
    return <Row className="p-3 col-12 list-main">
        {programas.filter(c => c.estatus === 2)
            .map(c => <Card style={{ background: backCard, color: TextCard }}
                as={Link} to={`/programa/${c.codigo}`} className="link-lista">
                <Card.Img src={getImagenURL(c.short)} />
                <Card.Body>{c.nombre}</Card.Body>
            </Card>)}
    </Row>
}
