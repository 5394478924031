import { useCallback, useEffect, useMemo, useState } from "react";
import { CountryList, dataContry } from "../../Config/countrystatecity";

interface PropsPais {
    pais: string,
}
export function VerContry({ pais }: PropsPais) {
    return useMemo(() => <CargarPais pais={pais} />, [pais]);
}
function CargarPais({ pais }: PropsPais) {
    const [data, setData] = useState<CountryList>({ name: '', iso2: '', phone: 0 });
    const cargar = useCallback(async () => {
        const data = await dataContry().getCountry(pais);
        if (data && data !== null) setData(data);
    }, [pais])
    // eslint-disable-next-line
    useEffect(() => { cargar() }, [pais]);
    return <><b>PAIS:</b> {data.name}</>
}
interface PropsEstado extends PropsPais {
    estado: string,
}
export function VerEstado({ pais, estado }: PropsEstado) {
    return useMemo(() => <CargarEstado pais={pais} estado={estado} />, [pais, estado]);
}
function CargarEstado({ pais, estado }: PropsEstado) {
    const [data, setData] = useState<CountryList>({ name: '', iso2: '', phone: 0 });
    const cargar = useCallback(async () => {
        const data = await dataContry().getCountryState(pais, estado);
        if (data && data !== null) setData(data);
    }, [pais, estado])
    // eslint-disable-next-line
    useEffect(() => { cargar() }, [pais, estado]);
    return <><b>ESTADO:</b> {data.name}</>
}