import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/es';
import './InputHoru.css';
import Stack from '@mui/material/Stack';
// import TextField from '@mui/material/TextField';
import { useState, useCallback, useEffect } from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
export interface Props {
    label: string
    error?: any;
    setData: (data: any) => void,
    value: string
    update?: string
}
export default function MaterialUIPickersHorus({ setData, value, label, update, }: Props) {
    // const isError = !!(update ? error[update] : error);
    const [h, set] = useState(dayjs('2023-11-17T' + value))
    const handle = useCallback((v: Dayjs) => set(v), [])
    useEffect(() => {
        const v2 = h.format('hh:mm a');
        if (update) setData({ [update]: v2 })
        else setData(v2)
    }, [h, setData, update])
    return (<LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={3} className='hora'>
            <DesktopTimePicker label={label} defaultValue={h} onChange={handle as any} />
        </Stack>
    </LocalizationProvider>);
}