import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Cargando from "../../../Components/Cargando";
import Fount404 from "../../../Components/Fount404";
import CR from "../Autenticacion/ClaveRecuperar";
import { Participante, conParticipante, ParticipanteNull } from "../Models";

export default function ClaveRecuperar() {
    const { code } = useParams<{ code: any }>();
    const [p, set] = useState<Participante>(ParticipanteNull);
    const [e, setErr] = useState<any | undefined>(undefined)
    useEffect(() => conParticipante().userForCode(code, set, setErr), [code])
    return <Row className="pt-5 mr-0 ml-0 mt-5 pb-5 mb-5">
        <Container>
            {e ? <Fount404 /> : p.id ? <Col md={{ offset: 4, span: 4 }}>
                <CR code={code} par={p} />
            </Col> : <Cargando />}
        </Container>
    </Row>
}