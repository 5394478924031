import { Component } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { RoC, RoVB, RoVS } from "../Components";
import { FieldControlText } from "../../../Components/Material/FieldControl";
import { Html } from "../../../Components/Html";
import { ContrySelector, StateSelector } from "../../../Components/Contry";
import { validarEmail } from "../../../Utils/Formatos";
import { Box, FormHelperText } from "@mui/material";
import { SelectBanderaFronTel } from "../../../Components/Contry/ContrySelector";

interface State {
    pais: string
    correo: string
    estado: string
    ciudad: string
    telefono: string
    suscrito: boolean
    phone: number | undefined
    nombre: string
    error: any
}
interface Prop {
    html: any,
    online?: boolean
    id: number
}
export default class Telefono extends Component<Prop, State> {
    constructor(props: Prop) {
        super(props);
        this.state = {
            pais: '',
            estado: '',
            ciudad: '',
            correo: '',
            telefono: '',
            phone: 0,
            nombre: '',
            suscrito: false,
            error: {}
        }
        this.setState = this.setState.bind(this);
    }
    render = () => {
        const {
            state: { pais, correo, estado, ciudad, telefono, nombre, phone, error, suscrito },
            props: { html }, setState, setPais, send
        } = this;
        return suscrito ? <Row className="boton mr-3 ml-1 p-2"><Button block><b>Gracias por suscribirte</b></Button></Row> :
            <Row className='mr-3 ml-1 p-2' >
                <Col className='text m-2 p-0' md={12}><Html html={html} /></Col>
                <RoC><FieldControlText size="small" input={{ maxLength: 200, }} error={error} update="nombre" setData={setState} label="Nombre*" value={nombre} /></RoC>
                <RoC><FieldControlText size="small" input={{ maxLength: 300, }} error={error} update="correo" setData={setState} label="Correo Electronico*" value={correo} /></RoC>
                <RoC><ContrySelector size="small" error={error.pais} defaultValue={pais} change={setPais} /></RoC>
                <RoVB val={!phone}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <SelectBanderaFronTel value={phone ? phone : 0} change={phone => setState({ phone })} />
                        <span>+{phone ? phone : 0}-</span>
                        <FieldControlText size="small" mask="999999999999" update="telefono" setData={setState} label="Whatsapp*" value={telefono} />
                    </Box>
                    {!!error['telefono'] ? <FormHelperText id={`telefono-error`} >
                        <span dangerouslySetInnerHTML={{ __html: error['telefono'] }} />
                    </FormHelperText> : <></>}
                </RoVB>
                <RoVS val={pais}><StateSelector size="small" error={error.estado} pais={pais} defaultValue={estado} change={estado => setState({ estado })} /></RoVS>
                <RoVS val={estado}><FieldControlText size="small" input={{ maxLength: 100 }} update="ciudad" error={error} setData={setState} label="Ciudad*" value={ciudad} /></RoVS>
                <Col className='boton m-2 p-0' md={12}><Button onClick={() => send()} block variant="primary"><b>¡Inicia ahora!</b></Button></Col>
            </Row>
    }
    setPais = (pais: string, phone: number | undefined) => {
        this.setState({ pais, phone })
    }
    getValidation = () => {
        const { pais, correo, estado, ciudad, telefono, nombre } = this.state;
        let val = true, error: any = {};
        if (estado === '') { error.estado = 'Debe seleccionar un estado'; val = false; }
        if (pais === '') { error.pais = 'Debe ingresar un pais'; val = false; }
        if (ciudad === '') { error.ciudad = 'Debe ingresar una ciudad'; val = false; }
        if (telefono === '') { error.telefono = 'Debe ingresar numero de telefono'; val = false; }
        if (!validarEmail(correo)) { error.correo = 'Direccion de correo no valido'; val = false; }
        if (nombre === '') { error.nombre = 'Debe ingresar un nombre'; val = false; }
        if (!val) this.setState({ error });
        return val;
    }
    componentDidUpdate = () => {
        const { pais, correo, estado, ciudad, telefono, nombre, error } = this.state;
        let e: any = {};
        if (estado === '' && error.estado) { e.estado = error.estado; }
        if (ciudad === '' && error.ciudad) { e.ciudad = error.ciudad; }
        if (pais === '' && error.pais) { e.pais = error.pais; }
        if (!validarEmail(correo)) { e.correo = error.correo; }
        if (telefono !== '' && error.telefono) { e.telefono = error.telefono; }
        if (nombre === '' && error.nombre) { e.nombre = error.nombre; }
        if (JSON.stringify(e) !== JSON.stringify(error)) this.setState({ error: e });
    }
    send = () => { }
}

