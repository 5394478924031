/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect } from 'react';
import { MetodoPago, conMetodoPago } from '../Pagos/MetodosPago/Models';
import { metodos } from './Data';
import { useLista } from '@/Utils/useActualizador';
export interface MetodosPagosType {
    MetodosPagos: MetodoPago[]
    actualizarMetodosPago: () => void
}
export const MetodosPagosContext = createContext<MetodosPagosType>({
    MetodosPagos: metodos, actualizarMetodosPago: () => { }
});
export default function MetodosPagosProvider({ children }: { children: any }) {
    const [MetodosPagos, actualizarMetodosPago] = useLista<MetodoPago>(conMetodoPago(), metodos);
    useEffect(() => { actualizarMetodosPago(); }, []);
    return <MetodosPagosContext.Provider value={{ MetodosPagos, actualizarMetodosPago }}>
        {children}
    </MetodosPagosContext.Provider>
}
export const SeccionConsumer = MetodosPagosContext.Consumer;