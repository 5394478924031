import { FormControl, InputLabel, FormHelperText } from "@mui/material";
import FieldText, { Props as padre } from "../Field/InputText";
export interface Props extends padre {
    label: string
    error?: any;
    size?: string
}

export default function FieldControlText(p: Props) {
    const { label, error, update, size } = p
    const isError = error ? !!(update ? error[update] : error) : false;
    return <FormControl size={size as any} error={isError} style={{ width: '100%' }}>
        <InputLabel size={size as any} style={{ backgroundColor: 'white' }} htmlFor={update}>{label}</InputLabel>
        <FieldText {...p} />
        {isError ? <FormHelperText id={`${update}-error`} >
            <span dangerouslySetInnerHTML={{ __html: update ? error[update] : error }} />
        </FormHelperText> : <></>}
    </FormControl>
}