import { Row, Container } from 'react-bootstrap';
import { Html } from '../../Components/Html';
import { getCSSCarruselInter, getSetting } from './styles';
import { getBorderCard } from '@/Utils/getBorder';
import SliderLazy from '@/Utils/SliderLazy';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import './style.css';
export default function CarruselCardText({ data: { itens, props } }: any) {
    const [height, set] = useState([{ id: 0, h: 5 }]);
    const setHeight = useCallback((h: any) => {
        if (height.some(he => he.id === h.id))
            set(height.map(he => he.id === h.id ? h : he))
        else set([...height, h])
    }, [height]);
    const { color, inter = '#000000' } = props;
    return <Row className='m-0' style={{ background: color, padding: '0 0 2rem 0' }}>
        <Container><SliderLazy className={getCSSCarruselInter(inter)} settings={getSetting(itens.length)}>
            {itens.map((i: any) => <Itens key={'itens' + i.id} iten={i} props={props} setHeight={setHeight} height={Math.max(...height.map(e => e.h))} />)}
        </SliderLazy></Container>
    </Row>
}
function Itens({ iten: { id, html, props: { color, back, border } }, props: { card, border1, gap }, height, setHeight }: any) {
    const d = useRef<any>();
    const [h, set] = useState(0);
    useEffect(() => { if (d.current?.clientHeight !== h) set(d.current.clientHeight); }, [h]);
    // eslint-disable-next-line
    useEffect(() => setHeight({ id, h }), [h]);
    return <div className='w-100 h-100 pt-2 pb-2 m-0'>
        <div style={s(card, border1, border, back, gap, height)} className="card mt-2 mb-3">
            <div ref={d} className='card-carrusel'>
                <Html style={{ color }} html={html} />
            </div>
        </div>
    </div>
}
const s = (card: any, border1: any, border: any, back: any, gap: any, minHeight: any): CSSProperties => {
    const style = getBorderCard(card, border1, border, back);
    return {
        ...style, minHeight,
        marginInline: gap + 'px',
        paddingBottom: '2rem'
    }
}