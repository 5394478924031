import { Col, Container, Row, Button } from "react-bootstrap";
import { useState, useEffect, useCallback } from "react";
import { conCapacitacion } from "../../Programas/Models";
import { findEventoFila } from "../../Context/Eventos";
import { useParams } from "react-router-dom";
import { findProgramFila } from "../../Context/Programas";
import { Cargando } from "@victorequena22/component-bootstrap";
import MaterialUIPickersHorus from "../../../Components/Material/FieldControl/InputHoru";
export default function Suscrito() {
    const { id } = useParams<{ id: any }>();
    const [sub, set] = useState({ evento: 0, id: 0, nombre: '' })
    useEffect(() => {
        conCapacitacion().dataSub(id, set)
    }, [id])
    return sub.id === 0 ? <Cargando /> : <Eve sub={sub} />
}
function Eve({ sub }: { sub: any }) {
    const e = findEventoFila(sub.evento);
    const { nombre } = findProgramFila(e.programa);
    return e.estatus !== 1 ? <Row>
        <Container className="p-4">
            <Row>
                <Col xs={12}>
                    <h3 className="text-danger"> Este evento ya no esta diponible</h3>
                </Col>
            </Row>
        </Container>
    </Row> : <Sub sub={sub} nombre={nombre} />
}
function Sub({ sub, nombre }: { sub: any, nombre: string }) {
    const [de, seti] = useState('12:00:00')
    const [hasta, setf] = useState('14:00:00')
    const [confir, setConfir] = useState(false)
    const send = useCallback(() => {
        conCapacitacion().llamame(`sub/${sub.id}`, { de, hasta }, () => setConfir(true))
    }, [de, hasta, sub.id])
    return confir ? <Row>
        <Container className="p-4">
            <Row>
                <Col xs={12}>
                    <h3 className="text-success"> Nos pondremos en contacto contigo.</h3>
                </Col>
            </Row>
        </Container>
    </Row> : <Row style={{ minHeight: '70vh' }}>
        <Container className="p-4">
            <Row>
                <Col xs={12}>
                    <h3>{sub.nombre.split(' ')[0].toLowerCase().replace(/(?<= )[^\s]|^./g, (a: any) => a.toUpperCase())} gracias por tu interés en nuestro programa {nombre}</h3>
                </Col>
                <Col xs={12}>
                    Ya que elegiste la opción de contacto "llamada",  indícanos el horario idóneo para contactarte.
                </Col>
                <Col lg={6}>
                    <br />
                    <MaterialUIPickersHorus label='¿A partir de qué hora estás disponible?' value={de} setData={seti} />
                </Col>
                <Col lg={6}>
                    <br />
                    <MaterialUIPickersHorus label='¿A qué hora dejas de estar disponible?' value={hasta} setData={setf} />
                </Col>
                <Col xs={12} className="text-right">
                    <br /><Button onClick={() => send()}>
                        <i className="fas fa-paper-plane" /> Enviar
                    </Button><br />
                </Col>
            </Row>
        </Container>
    </Row>
} 