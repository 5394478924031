import { don } from "@victorequena22/conneccion";
import { isLine } from "./OnLineCheker";
export type setData = (d: any) => void;
export { don };
export class Conexion {
    clase = '';
    state = '';
    init = true;
    getProxi = async (path: string) => new Promise<any>(async (resolve, reject) => {
        const token = localStorage.getItem('Authorization');
        const url = don + '/api/' + this.clase + path;
        try {
            const response = await fetch(url, { headers: { 'Authorization': 'Bearer ' + token } });
            const data = await response.json();
            if (data.error) reject(data.error);
            resolve(data);
        } catch (err) {
            resolve(err);
        }
    });
    postProxi = (path: string, data: any, setData?: setData, setError?: setData) => {
        const url = don + '/api/' + this.clase + path;
        this.post(url, data, setData, setError);
    }
    cambios = async (setData: setData, setNot?: () => void) => {
        try {
            const data = await this.getProxi('/cambios/' + this.state.replace(' ', '/'));
            if (Array.isArray(await data.data)) {
                if (await data.data.length !== 0) {
                    this.state = await data.fecha;
                    setData(await data.data);
                }
                if (setNot) setNot()
            }
        } catch (err) {
            console.log(err);
        }
    }
    lista = async (setData: setData) => {
        try {
            const data = await this.getProxi('/lista');
            if (Array.isArray(await data)) setData(await data);
        } catch (e) {
            if (isLine()) console.log(e);
        }
    }
    detalle = async (codigo: string | number, setData: setData) => {
        try {
            const data = await this.getProxi('/detalles/' + codigo);
            setData(await data);
        } catch (err) {
            if (isLine()) console.log(err);
        }
    }
    cancelar = async (codigo: string | number, setData?: setData) => {
        try {
            const data = await this.getProxi('/cancelar/' + codigo);
            if (setData) setData(data);
        } catch (err) {
            if (isLine()) console.log(err);
        }
    }
    nuevo = (data: any, setData?: setData, setError?: setData) => {
        this.post(don + '/api/' + this.clase + '/nuevo', data, setData, setError)
    }
    actualizar = (codigo: string | number, data: any, setData?: setData, setError?: setData) => {
        this.post(don + '/api/' + this.clase + '/actualizar/' + codigo, data, setData, setError);
    }
    get = async (url: string, setData: setData, setError?: setData) => {
        const token = localStorage.getItem('Authorization');
        try {
            const response = await fetch(url, { headers: { 'Authorization': 'Bearer ' + token } });
            const data = await response.json();
            if (data.error && setError) setError(data.error);
            setData(data);
        } catch (err) {
            if (setError) setError(err);
        }
    }
    post = (url = '', obj = {}, success = (_d: any) => { }, error = (_d: any) => { }) => {
        let token = localStorage.getItem('Authorization');
        let xhr = new XMLHttpRequest();
        xhr.responseType = 'json';
        xhr.open("POST", url);
        if (token !== null)
            xhr.setRequestHeader('Authorization', 'Bearer ' + token)
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== 4) {
                return;
            }
            if (xhr.status === 200) {
                if (xhr.response.errores) error(xhr.response.errores);
                else success(xhr.response);
            } else {
                if (isLine())
                    error(xhr);
            }
        };
        xhr.send(this.FormDataFotmat(obj));
    }
    FormDataFotmat = (o: any) => {
        const formData = new FormData();
        Object.keys(o).forEach(key => {
            switch (typeof o[key]) {
                case 'object':
                    formData.append(key, JSON.stringify(o[key]));
                    break;
                default:
                    formData.append(key, o[key]);
            }
        });
        return formData;
    };
}
class Commos extends Conexion {
    clase = 'Commons';
    tags = async (setData: setData) => {
        try {
            const data = await this.getProxi('/tags');
            if (Array.isArray(await data)) setData(await data);
        } catch (e) {
            if (isLine()) console.log(e);
        }
    }
}
export function conCommos() {
    return new Commos();
}
export function setCambio(data: any[], c: any[]) {
    const push = data.filter(d => {
        const i = c.findIndex(c => c.id === d.id);
        if (i > -1) {
            c[i] = d;
            return false;
        }
        return true;
    });
    return [...c, ...push];
}