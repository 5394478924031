import { Container, Row } from "react-bootstrap";
// import FiltrosProgramas from "./Filtros";
import VerProgramas from "./Ver";
import { data } from "./Data";
import { Html } from "@/Components/Html";
export default function MainProgramas() {
    const { background, html } = data;
    return <Row style={{ background }}><Container>
        <Html html={html} className="pl-5 pt-3" />
        <VerProgramas />
    </Container></Row>
}