var setLine = (_line: boolean) => { };
export function setLineFun(line: (line: boolean) => void) {
    setLine = line;
}
export function isLine() {
    if (navigator.onLine) {
        setLine(true);
        return true;
    } else {
        setLine(false);
        return false;
    }
}