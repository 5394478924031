import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { conFacilitador, facilitadorNull } from "../Models";
import VistaFacilitador from "./Vista";
import { data } from "../../../Data";
import { nomURL } from "@/Capacitacion/Context/Facilitadores";

export default function VerFacilitador() {
    const { id } = useParams<{ id: any }>();
    const [f, set] = useState<any>(getData(id));
    useEffect(() => {
        if (Number.isInteger(id))
            conFacilitador().detalle(id, set);
        else conFacilitador().nombre(id, set);
    }, [id])
    return <VistaFacilitador f={f} />
}
const getData = (cod: any) => {
    if (data.facilitador) {
        if (data.facilitador.id === cod) return data.facilitador
        if (nomURL(data.facilitador.nombre) === cod) return data.facilitador
    }
    return facilitadorNull;
}
