import { semaforo } from "@victorequena22/utiles";
import { setCambio, ConexionCompleta, setData, isLine } from "@victorequena22/conneccion";
class LandingConeccion extends ConexionCompleta {
    clase = 'Web/Landing';
    persistencia = 1000;
    cambios2 = async (model: any, id: any, setData: setData, setNot?: () => void) => {
        await this.getProxiCache(`/cambios2/${model}/${id}/${this.state.replace(' ', '/')}`,
            data => {
                if (data.data.length !== 0) {
                    this.state = data.fecha;
                    setData(data.data);
                }
                if (setNot) setNot()
            }, setNot);
    }
    lista2 = async (model: any, id: any, setData: setData, setNot?: () => void) => {
        try {
            const data = await this.getProxi(`/lista2/${model}/${id}`);
            if (Array.isArray(await data)) setData(await data);
        } catch (e: any) {
            if (isLine() && setNot) setNot();
        }
    }
    up = (indice: number, setData: () => void, setError: () => void) =>
        this.get(`/api/${this.clase}/up/${indice}`, setData, setError)
    down = (indice: number, setData: () => void, setError: () => void) =>
        this.get(`/api/${this.clase}/down/${indice}`, setData, setError)
    delete = (id: number, setData: () => void, setError: () => void) =>
        this.get(`/api/${this.clase}/delete/${id}`, setData, setError)
}
export const LandingUpdate = (con: LandingConeccion, model: any, id: any, u: any[], setData: (c: any[]) => void, cargando: boolean, setCargando: (c: boolean) => void) => {
    const s = semaforo()
    if (s.isTurn('Landing')) con.cambios2(model, id, data => {
        setData(setCambioPanel(data, u));
        s.next();
    }, () => {
        s.next();
        if (cargando) setCargando(false);
    });
}
export function setCambioPanel(data: any[], c: any[]) { return setCambio(data, c); }
export function conLanding() { return new LandingConeccion(); }