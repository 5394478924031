import { useContext } from "react";
import { Link } from "react-router-dom";
import { ProgramaContext } from "../Context/Programas";



export default function ProgramaMenuTemplate() {
    const { programas } = useContext(ProgramaContext);
    if (programas.filter(c => c.estatus === 2).length === 0) return <></>
    return <Link to='/programas' className='dropdown-item'><b>Programas</b></Link>
}