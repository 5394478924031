import { useParams } from "react-router-dom";
import { programToId } from "../../../../Context/Programas";
import { suscrito, user, Auth } from "../../../../../Template/Seccion";
import { conCapacitacion } from "../../../Models";
import Participante2 from "../../../../Paneles/Suscribirse/Participante";
class Par2 extends Participante2 {
    send = () => {
        const { id } = this.props;
        conCapacitacion().Suscribirse(id, user);
    }
}
export default function Participante({ html }: { html: any }) {
    const id = programToId(useParams<{ id: any }>().id);
    const s = suscrito(id);
    const u = Auth();
    return <Par2 html={html} suscrito={s} user={u} id={id} />
}