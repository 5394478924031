import { semaforo } from '@victorequena22/utiles';
import { isLine } from '../../../Config/OnLineCheker';
import { ConexionCompleta, setCambio, setData } from '@victorequena22/conneccion';
import { CapacitacionFila } from './Models';
import { validarEmail } from '../../../Utils/Formatos';
type capRest = [number, string, number, string, string, number, string, number, number, any, number[]]
export function arrayToCapacitacion(f: capRest[]): CapacitacionFila[] {
    return f.map((a) => {
        return {
            id: a[0],
            codigo: a[1],
            categoria: a[2],
            nombre: a[3],
            descripcion: a[4],
            horas: a[5],
            short: a[6],
            estatus: a[7],
            usuario: a[8],
            lista: a[9] ? a[8] : {},
            tags: a[10] ? a[10] : [],
        }
    });
}
class CapacitacionConecction extends ConexionCompleta {
    clase = 'Programa';
    persistencia = 1000;
    async Suscribirse(id: any, setData?: setData) {
        try {
            const data = await this.getProxi(`/Suscribirse/Participante/${id}`);
            if (setData) setData(await data);
        } catch (e) {
            if (isLine()) console.log(e);
        }
    }
    async interes(id: any, setData?: setData) {
        try {
            const data = await this.getProxi(`/Suscribirse/interes/${id}`);
            if (setData) setData(await data);
        } catch (e) {
            if (isLine()) console.log(e);
        }
    }
    Suscribir(id: any, data: any, setData?: setData, setError?: setData) {
        this.postProxi(`/Suscribirse/telefono/${id}`, data, setData, setError);
    }
    evento(id: any, data: any, setData?: setData, setError?: setData) {
        this.postProxi(`/Suscribirse/evento/${id}`, data, setData, setError);
    }
    async dataSub(id: any, setData?: setData) {
        try {
            const data = await this.getProxi(`/Suscribirse/detalles/${id}`);
            if (setData) setData(await data);
        } catch (e) {
            if (isLine()) console.log(e);
        }
    }
    llamame(id: any, data: any, setData?: setData, setError?: setData) {
        this.postProxi(`/Suscribirse/llamame/${id}`, data, setData, setError);
    }
}
export function conCapacitacion() {
    return new CapacitacionConecction();
}
function setCambioCapacitacion(data: any[], c: CapacitacionFila[]) {
    return setCambio(arrayToCapacitacion(data), c);
}
export const CapacitacionUpdate = (c: CapacitacionConecction, u: CapacitacionFila[], setData: (c: CapacitacionFila[]) => void) => {
    const s = semaforo();
    if (s.isTurn('Capacitacion'))
        c.cambios(
            data => {
                setData(setCambioCapacitacion(data, u));
                s.next();
            },
            () => s.next()
        );

}
class RecursosConeccion extends ConexionCompleta {
    clase = 'Programa/Recursos';
    withProgram = async (id: number, setData: (d: any) => void, setError: (d: any) => void) => {
        try {
            const f = await this.getProxi(`/withProgram/${id}`);
            setData(f)
        } catch (e) { setError(e) }
    }
    delete = async (id: number, setData: (d: any) => void, setError: (d: any) => void) => {
        try {
            const f = await this.getProxi(`/delete/${id}`);
            setData(f)
        } catch (e) { setError(e) }
    }
}
export function conRecursos() {
    return new RecursosConeccion();
}



export const getValidation = ({ pais, correo, estado, ciudad, telefono, nombre }: any, setError: (d: any) => void) => {
    var val = true, error: any = {};
    if (estado === '') { error.estado = 'Debe seleccionar un estado'; val = false; }
    if (pais === '') { error.pais = 'Debe ingresar un pais'; val = false; }
    if (ciudad === '') { error.ciudad = 'Debe ingresar una ciudad'; val = false; }
    if (telefono === '') { error.telefono = 'Debe ingresar un telefono'; val = false; }
    if (!validarEmail(correo)) { error.correo = 'Direccion de correo no valido'; val = false; }
    if (nombre === '') { error.nombre = 'Debe ingresar un nombre'; val = false; }
    if (!val) setError(error);
    return val;
}
export const setValidation = ({ pais, correo, estado, ciudad, telefono, nombre }: any, error: any, setError: (d: any) => void) => {
    var e: any = {};
    if (estado === '' && error.estado) { e.estado = error.estado; }
    if (ciudad === '' && error.ciudad) { e.ciudad = error.ciudad; }
    if (pais === '' && error.pais) { e.pais = error.pais; }
    if (!validarEmail(correo)) { e.correo = error.correo; }
    if (telefono === '' && error.telefono) { e.telefono = error.telefono; }
    if (nombre === '' && error.nombre) { e.nombre = error.nombre; }
    if (JSON.stringify(e) !== JSON.stringify(error)) setError(e);
}