import { Participante } from "../../Models"

export function MyStatus({ participante }: { participante?: Participante }) {
    if (participante) {
        return participante.status === 1 ? <h5 className='row p-3 w-100 text-primary'>
            Ya se encuentra preinscrito en esta cohorte
        </h5> : <h5 className='row p-3 w-100 text-success'>
            Ya se encuentra inscrito en esta cohorte
        </h5>
    }
    return <></>
}