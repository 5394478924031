import { Row, Container } from 'react-bootstrap';
import { useState } from 'react';
import { Itens } from './Itens';
import './style.css';
import { Zoom } from '../../Components/Zoom';
import { css } from '@emotion/css';
export default function Galeria({ data: { props, itens } }: { data: any }) {
    const [open, setOpen] = useState(false);
    const [select, setSelect] = useState(itens[0].id);
    const { color, width, gap } = props;
    return <Row className='m-0 p-0' style={{ background: color }}>
        <Zoom color={color} imgs={itens.map((i: any) => { return { id: i.id, src: i.props.img } })}
            select={select} setSelect={setSelect} open={open} setOpen={setOpen} />
        <Container className={getCSS(width)} style={{ display: 'grid', gap, gridAutoFlow: 'dense' }}>
            {itens.map((i: any) => <Itens key={'itens' + i.id} iten={i} config={props}
                click={() => { setOpen(true); setSelect(i.id) }} />)}
        </Container>
    </Row>
}
function getCSS(width: any) {
    return css`@media (min-width: 768px) { grid-template-columns: repeat(auto-fill, minmax(${width}px, 1fr))}`;
}