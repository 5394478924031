import { createContext, useContext } from 'react';
import { useID } from '@/Utils/ReactUtils';
import { useLanding } from '@/Utils/useActualizador';
import { findProgramFila } from '@/Capacitacion/Context/Programas';
export interface LandingType {
    panels: any[];
    actualizar: () => void;
    cargando: boolean
}
export const LandingContext = createContext<LandingType>({ panels: [], actualizar: () => { }, cargando: true })
export default function LandingProvider({ children }: { children: any }) {
    const p = findProgramFila(useID());
    const [panels, cargando, actualizar] = useLanding('programa', p.id);
    return <LandingContext.Provider value={{ panels, actualizar, cargando }}>{children}</LandingContext.Provider>
}
export const SeccionConsumer = LandingContext.Consumer;

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useLandingPrograma = () => useContext(LandingContext);
