/* eslint-disable react-hooks/rules-of-hooks */
import { useState, createContext, useEffect, useCallback, useContext } from 'react';
import { setLineFun } from '../Config/OnLineCheker';
import { don } from '../Config/Coneccion';
import { Participante, ParticipanteNull } from '../Capacitacion/Participante/Models';
import { Participante as p } from '../Capacitacion/Evento/Models';
import { Pago } from '../Capacitacion/Pagos/Models';
const ParNull = { ...ParticipanteNull, certificados: [], intereses: [], pagos: [], eventos: [], programas: [], encuesta: [], suscripciones: [] };
interface Encuesta {
    evento: number
    respuesta: string
}
interface User extends Participante {
    pagos: Pago[]
    eventos: p[]
    programas: number[]
    encuesta: Encuesta[]
    suscripciones: number[]
    intereses: number[]
    certificados: {
        codigo: string,
        certificado: number,
    }[]
}
export interface SeccionType {
    data: {
        User: User
        online: boolean
        cargando: boolean
        seccion: boolean
    }
    logout: () => void,
    user: () => void
}
export const SeccionContext = createContext<SeccionType>({
    data: { User: ParNull, online: false, cargando: true, seccion: false },
    logout: () => { },
    user: () => { }
})
export var user = () => { };
export function SeccionProvider({ children }: { children: any }) {
    const [User, setUser] = useState(ParNull);
    const [online, setOnline] = useState(false);
    const [cargando, setCargando] = useState(true);
    const [seccion, setSeccion] = useState(false);
    setLineFun(setOnline);
    user = useCallback(async () => {
        try {
            let token = localStorage.getItem('Authorization');
            let response = await fetch(don + '/api/Auth/me', { headers: { 'Authorization': 'Bearer ' + token } });
            let data: any = await response.json();
            setCargando(false);
            if (data !== null) {
                setSeccion(true);
                setUser(data);
            } else {
                setSeccion(false);
            }
            //Actualizadar nuevos datos en cache
        } catch (err) {
            //console.log(err);
        }
    }, [])
    const logout = useCallback(async () => {
        try {
            //solicitar nuevos datos
            let token = localStorage.getItem('Authorization');
            let response = await fetch(don + '/api/Auth/close', { headers: { 'Authorization': 'Bearer ' + token } });
            localStorage.removeItem('Authorization');
            await response.json();
            //cargar nuevos datos
            user();
            //Actualizadar nuevos datos en cache
        } catch (err) {
            //console.log(err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { user() }, []);
    return <SeccionContext.Provider value={{
        data: { User, online, cargando, seccion },
        user, logout,
    }}>{children}</SeccionContext.Provider>
}
export const SeccionConsumer = SeccionContext.Consumer;
export const useSeccion = () => useContext(SeccionContext);
export function findEncuesta(id: number): Encuesta | undefined {
    const { data: { User: { encuesta } } } = useContext(SeccionContext);
    return encuesta?.find(e => e.evento === id);
}
export function findEvento(id: number) {
    const { data: { User: { eventos } } } = useContext(SeccionContext);
    return eventos?.find(e => e.id === id);

}
export function findPrograma(id: number) {
    const { data: { User: { programas } } } = useContext(SeccionContext);
    return programas?.find(e => e === id);
}
export function findPago(id: number) {
    const { data: { User: { pagos } } } = useContext(SeccionContext);
    return pagos?.find(e => e.model === 'evento' && e.model_id === id);
}
export function suscrito(id: number): boolean {
    const { data: { User: { suscripciones } } } = useContext(SeccionContext);
    return suscripciones?.some(e => e === id);
}
export function interesado(id: number): boolean {
    const { data: { User: { intereses } } } = useContext(SeccionContext);
    return intereses?.some(e => e === id);
}
export function Auth(): User {
    const { data: { User } } = useContext(SeccionContext);
    return User;
}
export function isLogin(): boolean {
    const { data: { seccion } } = useContext(SeccionContext);
    return seccion;
}