import { Row, Col } from 'react-bootstrap';
import { Html } from '../../Components/Html';
import { getImagenURL } from '../../Utils/Imagen';

export default function Suscribir2({ data: { props, html }, children }: any) {
    const { backgroundColor: c, img, hideText } = props;
    return <div style={{ padding: '60px 0 0 0', background: c }}>
        <div className="container">
            <Row>
                <Col xs={12} md={7} xl={8}>
                    <Row className='d-flex flex-row-reverse text-center'>
                        <Col xs={12} xl={img ? 6 : 12}>
                            <Row className='m-2 p-2'><Html html={html} /></Row>
                            <Row className='m-2 p-2 h-xs h-sm'><Html html={hideText} /></Row>
                        </Col>
                        {img ? <Col xs={12} xl={6} className='h-md h-lg'>
                            <img style={{ aspectRatio: 409 / 483 }} key={img} src={getImagenURL(img)} width='100%' alt='panel a' />
                        </Col> : <></>}
                    </Row>
                </Col>
                {children}
            </Row>
        </div>
    </div>
}

