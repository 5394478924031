import { useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { fechaToNumber } from "@victorequena22/utiles";
import { Evento } from "../../Evento/Models/Model";
import { MetodoSelector } from "../MetodosPago/MetodoSelector";
import NuevoPago from "./From";

interface Props {
    evento: Evento
    update: () => void
    open: boolean
    close: () => void
}

export default function RegistrarPago({ update, evento, open, close }: Props) {
    const [m, setMetodo] = useState(0)
    const { metodos, id, precios } = evento;
    const p = precios.find(p => inRangeFechas(p.inicio, p.final));
    const precio = p ? p : precios[precios.length - 1];
    const metodo = metodos.find(b => b.id === m);
    return <Modal show={open} size={metodo ? 'xl' : undefined} centered onHide={close}>
        <Modal.Header closeButton>
            <h2 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                <span><b> {metodo ? 'Registrar Pago' : 'Seleccionar método de pago'}</b></span>
            </h2>
        </Modal.Header>
        <Modal.Body>
            <Row>
                {metodo ?
                    <NuevoPago setMetodo={setMetodo} update={() => { update(); close(); }}
                        model_id={id} model="evento" metodo={metodo} precio={precio.precio} />
                    : <MetodoSelector metodos={metodos} setData={setMetodo} />}
            </Row>
        </Modal.Body>
    </Modal>
}

const inRangeFechas = (i: string, f: string) => {
    const inicio = parseInt((fechaToNumber(i) / 1000000) + '') + 1;
    const final = parseInt((fechaToNumber(f) / 1000000) + '') + 1;
    const dia = parseInt((fechaToNumber() / 1000000) + '');
    return (dia >= inicio) && (final >= dia)
}