import { MetodoPago } from "../../Pagos/MetodosPago/Models";
import { Precios } from "./Precios";
export type { Precios }
export interface TypeAuxiliar {
    id: number,
    nombre: string,
    texto: any,
    estatus: number
}
export interface TypeAuxiliarLista {
    id: number,
    nombre: string,
    estatus: number
}
export interface Costo {
    id: number,
    nombre: string,
    costo: number,
    estatus: number
}
export const costoNull = {
    id: 0,
    nombre: '',
    costo: 0,
    estatus: 0
}

export interface EventoLista {
    id: number;
    short?: string;
    cohorte: number;
    codigo: string;
    programa: number;
    nombre: string;
    descripcion: string;
    fecha: string;
    inicio: string;
    final: string;
    pais: string;
    estado: string;
    ciudad: string;
    direccion: string;
    estatus: number;
    publicacion: number;
    usuario: number;
    lista: any;
    facilitadores: number[];
    avales: number[];
    tags: number[];
    landing: boolean;
}
export interface Participante {
    id: number;
    status: number;
}
export interface Evento extends EventoLista {
    precios: Precios[];
    certificado: number;
    herencia: number[];
    metodos: MetodoPago[];
    participantes: Participante[];
    consideracion: number;
    norma: number;
    props: any;
    data: any;
}

export interface Asistencia {
    id: number
    evento: number
    actualizado: string
    codigo: string
    fecha: string
    inicio: string
    final: string
    estatus: number
    asistentes: number
    props: { link?: string }
}
export const auxiliarNull: TypeAuxiliar = {
    id: 0,
    nombre: '',
    texto: [],
    estatus: 0
}

export const eventoNull: Evento = {
    id: 0,
    precios: [],
    metodos: [],
    certificado: 0,
    short: '',
    cohorte: 0,
    codigo: '',
    programa: 0,
    nombre: '',
    descripcion: '',
    fecha: '',
    inicio: '',
    final: '',
    pais: '',
    estado: '',
    ciudad: '',
    direccion: '',
    estatus: 0,
    publicacion: 0,
    usuario: 0,
    participantes: [],
    lista: {},
    tags: [],
    herencia: [],
    facilitadores: [],
    avales: [],
    consideracion: 0,
    landing: false,
    norma: 0,
    props: {},
    data: {}
}