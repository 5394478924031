import { useContext } from "react";
import { Link } from "react-router-dom";
import { EventoContext } from "../Context/Eventos";



export default function EventoMenuTemplate() {
    const { eventos } = useContext(EventoContext);
    if (eventos.filter(e => e.publicacion && e.estatus && e.estatus < 3).length === 0) return <></>
    return <Link to='/eventos' className='dropdown-item'><b>Eventos</b></Link>
}