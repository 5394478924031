/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col } from "react-bootstrap";
import Cargando from "../../Components/Cargando";
import { Html } from "../../Components/Html";
import { findProgram } from "../Context/Programas";

export default function Programa({ id }: { id: number }) {
    const programa: any = findProgram(id)
    if (programa.id === 0 || programa.resumen === undefined) return <Cargando />
    return <>
        <Col md={12}>
            <h3 className='w-100 text-info'><b>Resumen</b></h3>
            <Html html={programa.resumen} /> 
        </Col>
        <Col md={12}>
            <h3 className='w-100 text-info'><b>Contenidos</b></h3>
            <Html html={programa.contenido} />
        </Col>
    </>
} 