import { FormControl, InputLabel, FormHelperText, OutlinedInput } from "@mui/material";

interface Props {
    label: string;
    data?: string;
    value: string;
    error?: any;
    maxLength?: number
    maxRows?: number
    xs?: boolean;
    setData: (data: any) => void;
    type?: string;
    mask?: string
}
export default function InputFild({ label, mask, type, data, value, setData, error, maxLength, maxRows, xs }: Props) {
    const isError = !!(data ? error[data] : error);
    const Mask = (val: string, mask: string) => {
        let nval = '';
        if (val.length > 0) {
            mask.split('').forEach((m: string, i: number) => {
                if (m === 'a' && val[i] !== undefined) {
                    if (/[A-Za-z]/g.test(val[i])) {
                        nval += val[i];
                    }
                } else if (m === 'A' && val[i] !== undefined) {
                    if (/[A-Za-z]/g.test(val[i])) {
                        nval += val[i].toUpperCase();
                    }
                } else if (m === '9' && val[i] !== undefined) {
                    if (/[0-9]/g.test(val[i])) {
                        nval += val[i];
                    }
                } else if (m === '*' && val[i] !== undefined) {
                    if (/[A-Za-z0-9]/g.test(val[i])) {
                        nval += val[i];
                    }
                } else if (val[i] !== undefined) {
                    if (nval.length === i)
                        nval += m;
                } else if (val[i] === undefined) {
                    if (m !== 'a' && m !== 'A' && m !== '9' && m !== '*')
                        if (nval.length === i)
                            nval += m;
                }
            });
            return nval;
        } else {
            return '';
        }
    };
    return <FormControl error={isError} style={{ width: '100%' }}>
        <InputLabel style={{ backgroundColor: xs ? undefined : 'white', padding: '0 .25rem 0 .25rem' }} htmlFor={data}>{label}</InputLabel>
        <OutlinedInput aria-describedby={`${data}-error`}
            maxRows={maxRows} sx={xs ? { m: 1, height: '2.5rem' } : undefined}
            componentsProps={{
                input: {
                    maxLength, type, id: data,
                }
            }}
            defaultValue={value} onChange={(p) => {
                let { value } = p.currentTarget;
                if (mask) value = Mask(value, mask);
                p.currentTarget.value = value;
                if (data) setData({ [data]: value });
                else setData(value);
            }} />
        {isError ? <FormHelperText id={`${data}-error`}>
            {data ? error[data] : error}
        </FormHelperText> : <></>}
    </FormControl>
} 