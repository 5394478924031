/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Container, Row, Button } from "react-bootstrap";
import { useState, useCallback, useContext } from "react";
import { conCapacitacion } from "../../Programas/Models";
import { findEventoFila } from "../../Context/Eventos";
import { useParams } from "react-router-dom";
import { findProgramFila } from "../../Context/Programas";
import MaterialUIPickersHorus from "../../../Components/Material/FieldControl/InputHoru";
import { SeccionContext } from "../../../Template/Seccion";
import ModalLogin from "../../Participante/Modales/ModalLogin";
export default function Contactame() {
    const { id } = useParams<{ id: any }>();
    const e = findEventoFila(parseInt(id));
    const { nombre } = findProgramFila(e.programa);
    return e.estatus !== 1 ? <Row>
        <Container className="p-4">
            <Row>
                <Col xs={12}>
                    <h3 className="text-danger"> Este evento ya no esta diponible</h3>
                </Col>
            </Row>
        </Container>
    </Row> : <Seccion nombre={nombre} />
}

function Seccion({ nombre }: { nombre: string }) {
    const { data: { seccion } } = useContext(SeccionContext);
    if (!seccion) return <Registrarse />
    return <Sub nombre={nombre} />
}

function Sub({ nombre }: { nombre: string }) {
    const { id } = useParams<{ id: any }>();
    const { data: { User } } = useContext(SeccionContext);
    const [de, seti] = useState('12:00:00')
    const [hasta, setf] = useState('14:00:00')
    const [confir, setConfir] = useState(false)
    const send = useCallback(() => {
        conCapacitacion().llamame(`evento/${id}`, { de, hasta }, () => setConfir(true))
    }, [de, hasta, id])
    return confir ? <Row>
        <Container className="p-4">
            <Row>
                <Col xs={12}>
                    <h3 className="text-success"> Nos pondremos en contacto contigo.</h3>
                </Col>
            </Row>
        </Container>
    </Row> : <Row style={{ minHeight: '70vh' }}>
        <Container className="p-4">
            <Row>
                <Col xs={12}>
                    <h3>{User.nombre.split(' ')[0].toLowerCase().replace(/(?<= )[^\s]|^./g, (a: any) => a.toUpperCase())} gracias por tu interés en nuestro programa {nombre}</h3>
                </Col>
                <Col xs={12}>
                    Ya que elegiste la opción de contacto "llamada",  indícanos el horario idóneo para contactarte.
                </Col>
                <Col lg={6}>
                    <br />
                    <MaterialUIPickersHorus label='¿A partir de qué hora estás disponible?' value={de} setData={seti} />
                </Col>
                <Col lg={6}>
                    <br />
                    <MaterialUIPickersHorus label='¿A qué hora dejas de estar disponible?' value={hasta} setData={setf} />
                </Col>
                <Col xs={12} className="text-right">
                    <br /><Button onClick={() => send()}>
                        <i className="fas fa-paper-plane" /> Enviar
                    </Button><br />
                </Col>
            </Row>
        </Container>
    </Row>
}
export function Registrarse() {
    const [open, setOpen] = useState(false);
    return <Row style={{ minHeight: '70vh' }}>
        <Container className="p-4">
            <Row>
                <Col md={12}>
                    <ModalLogin open={open} close={() => setOpen(false)} />
                    <a className="text-primary" onClick={() => setOpen(true)}  >
                        <h3>Por favor inicie sesión para poder continuar con la operacion.</h3>
                    </a>
                </Col>
            </Row>
        </Container>
    </Row>
}