import { numberFormat } from "@victorequena22/utiles"
import { Button, Col, FormGroup, Row } from "react-bootstrap"
import { MetodoPago } from "./Models"

interface Props {
    metodos: MetodoPago[]
    setData: (m: number) => void
}
export function MetodoSelector({ metodos, setData }: Props) {
    return <Row>
        {metodos.map(m => <Col key={`metodo${m.id}`} as={FormGroup} md={12}>
            <Button onClick={() => setData(m.id)} variant="link" block>{m.metodo}</Button>
        </Col>)}
    </Row>
}

interface Props2 {
    metodo: MetodoPago
    dolar: number
    precio: number
    setData: (m: number) => void
}
export function MetodoMostrar({ metodo: { metodo, props, dolar: d }, dolar, precio, setData }: Props2) {
    return <Row>
        <Col as={FormGroup} md={12}>
            <h3 className="m-0 font-weight-bold text-primary mr-auto align-self-center">{metodo}</h3>
        </Col>
        {props.map(p => <Col as={FormGroup} md={12}>
            <b className='ml-3'>{p.descripcion}:</b> {p.dato}
        </Col>)}
        <Col as={FormGroup} md={12}>
            <b className='ml-3'>Monto a pagar:</b> {numberFormat(precio)}
        </Col>
        {d ? <></> : <Col as={FormGroup} md={12}>
            <b className='ml-3'>Monto en Bolivares:</b> {numberFormat(precio * dolar)}
        </Col>}
        <Col as={FormGroup} md={12}>
            <Button onClick={() => setData(0)} variant="danger" block>SELECCIONAR OTRO</Button>
        </Col>
    </Row>
}