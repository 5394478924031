import { semaforo } from "@victorequena22/utiles";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";


const s = semaforo();

export const useSemaforo = (tur: string, update: () => Promise<void>) => {
    const actualizar = useCallback(async () => { if (s.isTurn(tur)) { await update(); s.next(); } }, [update, tur]);
    useEffect(() => {
        const interval = setInterval(actualizar, 10000);
        return () => clearInterval(interval);
    }, [actualizar]);
    useEffect(() => { s.add(tur); return () => s.clean(tur); }, [tur]);
}
export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
export const useID = () => {
    const { id } = useParams<{ id: any }>();
    return id;
}