import React, { useState } from "react";
import { OutlinedInput } from "@mui/material";
import { focusId } from "../../../Utils/Formatos";
export interface Props {
    setData: (data: any) => void,
    value: string
    mask?: string
    update?: string
    upper?: boolean
    lower?: boolean
    only?: 'letter' | 'alphanumeric' | 'number'
    input?: React.HTMLProps<HTMLInputElement>
    next?: () => void
    maxRows?: number
    size?: string
    endAdornment?: any
}
export default function FieldText({ endAdornment, size, setData, value, mask, update, maxRows, upper, lower, only, next, input }: Props) {
    const [id] = useState(`inputText${update}${Math.random()}`);
    const Props = () => {
        const props: any = { ...input };
        props.value = undefined;
        return { ...props };
    }
    const match = (val: string, only: 'letter' | 'alphanumeric' | 'number') => {
        let nval = '';
        if (val.length > 0) {
            val.split('').forEach((m: string) => {
                switch (only) {
                    case 'alphanumeric':
                        if (/[A-Za-z0-9]/g.test(m)) nval += m;
                        break;
                    case 'letter':
                        if (/[A-Za-z]/g.test(m)) nval += m;
                        break;
                    case 'number':
                        if (/[0-9]/g.test(m)) nval += m;
                        break;
                }
            });
            return nval;
        }
        return '';
    }
    const Mask = (val: string, mask: string) => {
        let nval = '';
        if (val.length > 0) {
            mask.split('').forEach((m: string, i: number) => {
                if (m === 'a') {
                    if (/[A-Za-z]/g.test(val[i])) {
                        nval += val[i];
                    }
                } else if (m === 'A') {
                    if (/[A-Za-z]/g.test(val[i])) {
                        nval += val[i].toUpperCase();
                    }
                } else if (m === '9') {
                    if (/[0-9]/g.test(val[i])) {
                        nval += val[i];
                    }
                } else if (m === '*') {
                    if (/[A-Za-z0-9]/g.test(val[i])) {
                        nval += val[i];
                    }
                } else if (val[i] !== undefined) {
                    if (nval.length === i)
                        nval += m;
                } else if (val[i] === undefined) {
                    if (m !== 'a' && m !== 'A' && m !== '9' && m !== '*')
                        if (nval.length === i)
                            nval += m;
                }
            });
            return nval;
        } else {
            return '';
        }
    };
    return <OutlinedInput size={size as any} key={`${update}`} aria-describedby={`${update}-error`}
        maxRows={maxRows} componentsProps={{ input: { ...Props(), id } }} endAdornment={endAdornment}
        defaultValue={value} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            let { value } = e.currentTarget;
            if (only) value = match(value, only);
            if (mask) value = Mask(value, mask);
            if (upper) value = value.toUpperCase();
            if (lower) value = value.toLowerCase();
            e.currentTarget.value = value;
            if (update) setData({ [update]: value });
            else setData(value);
        }}
        onFocus={(e: any) => { focusId(id); if (input && input.onFocus) input.onFocus(e); }}
        onBlur={(e: any) => { if (input && input.onBlur) input.onBlur(e); }}
        onKeyUp={(e: any) => {
            if (e.keyCode === 13) if (next) next();
            if (input && input.onKeyUp) input.onKeyUp(e);
        }} />
}