/* eslint-disable react-hooks/rules-of-hooks */
import { useState, createContext, useEffect, useCallback, useContext } from 'react';
import { conFacilitador } from './Models';
export interface EspecialidadesType {
    especialidades: any[]
    actualizar: () => void
}
export const initEspecialidades: EspecialidadesType = {
    especialidades: [], actualizar: () => { }
}
export const EspecialidadContext = createContext<EspecialidadesType>(initEspecialidades);
export default function EspecialidadProvider({ children }: { children: any }) {
    const [especialidades, setLista] = useState<any[]>([]);
    const [con] = useState(conFacilitador());
    const actualizar = useCallback(() => con.especialidades(setLista), [con])
    useEffect(() => { actualizar() }, [actualizar]);
    return <EspecialidadContext.Provider value={{ especialidades, actualizar }}>
        {children}
    </EspecialidadContext.Provider>
}
export const getEspecialidades = () => useContext(EspecialidadContext);