
export interface FacilitadorFila {
    id: number;
    foto: string;
    nombre: string;
    experiencia: number;
    descripcion: any[];
    estatus: number
}
export interface Facilitador extends FacilitadorFila {
    foto: string;
    pais: string;
    estado: string;
    ciudad: string;
    direccion: string;
    alcance: string[];
    programas: number[];
    especialidades: number[];
    trabajos: any[];
    laboral: any[];
    publicaciones: any[];
    premios: any[];
    redes: any[];
    estudios: any[];
    data: any;
    props: any;
}
export const facilitadorNull: Facilitador = {
    id: 0,
    nombre: '',
    experiencia: (new Date()).getFullYear(),
    descripcion: [],
    foto: '',
    pais: '',
    estado: '',
    ciudad: '',
    direccion: '',
    alcance: [],
    programas: [],
    estudios: [],
    redes: [],
    especialidades: [],
    trabajos: [],
    laboral: [],
    publicaciones: [],
    premios: [],
    data: {},
    props: {},
    estatus: 1
}