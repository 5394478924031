import { Row, Container, Card, Col } from 'react-bootstrap';
import { fechaToNumber } from "@victorequena22/utiles";
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { EventoContext } from '../Capacitacion/Context/Eventos';
import { ProgramaContext, findProgram } from '../Capacitacion/Context/Programas';
import { getImagenURL } from '../Utils/Imagen';
import SliderLazy from '@/Utils/SliderLazy';
import { getCSSCarruselInter, getSetting } from './CarruselCardText/styles';
import { Html } from '@/Components/Html';
export default function Eventos({ data: { props, html } }: any) {
    const { color, filtroType, filtro, inter } = props;
    const { eventos } = useContext(EventoContext);
    const { programas } = useContext(ProgramaContext);
    const e = eventos.filter(e => e.publicacion && e.estatus && e.estatus < 3)
        .filter(e => {
            if (filtro) {
                if (filtroType === 'program') return e.programa === filtro;
                if (filtroType === 'categoria') return programas.filter(p => p.categoria === filtro).some(p => e.programa === p.id);
                if (fechaToNumber() > fechaToNumber(e.final)) return false;
            }
            return true;
        });
    if (e.length === 0) return <></>
    return <Row className='m-0' style={{ background: color, padding: '0 0 2rem 0' }}>
        <Container>
            {html !== null && html.length > 0 ? <Row className='pl-2 pr-2 mt-2 w-100'><Html className='w-100' html={html} /></Row> : <></>}
            <SliderLazy settings={getSetting(e.length)} className={getCSSCarruselInter(inter)}>
                {e.map(e => <Itens key={`EventoShort${e.codigo}`} iten={e} props={props} />)}
            </SliderLazy>
        </Container>
    </Row>
}
function Itens({ props: { gap }, iten: { id, short, programa: p, codigo } }: any) {
    const { short: s } = findProgram(p);
    return <Col className='m-0 p-0'>
        <Card as={Link} to={`/evento/${codigo}`} style={{ margin: `0 ${gap}px 0 ${gap}px` }} className="c-content-person-1 c-option-2 mt-2">
            <Card.Img style={{ aspectRatio: '1' }} variant="top" src={getImagenURL(short === '' ? s : short, 2)} alt={`evento ${id}`} />
        </Card>
    </Col >
}