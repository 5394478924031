import { css } from '@emotion/css';
import { Container, FormGroup, Button } from 'react-bootstrap';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Invert } from '../Components/Invert';
import Input from '../Utils/Input';
import { post } from '../Utils/Post';
import './Suscribir.css';
const focus = () => document.getElementById('correo')?.focus();
export default function Suscribir({ data }: { data: any }) {
    const { props: { input, inputText, button, buttonText, backgroundColor, padding } } = data;
    const [nombre, setNombre] = useState('');
    const [correo, setCorreo] = useState('');
    const [sus, setSus] = useState(false);
    const [valid, setValit] = useState(false);
    const suscribirse = () => {
        if (val(nombre, correo)) post('/api/Web/Suscribirse/nuevo', { nombre, correo }, () => {
            toast.success('GRACIAS POR SUSCRIBIRSE');
            setSus(true)
        }); else setValit(true)
    };
    return <div style={{ paddingBlock: padding, background: backgroundColor }}>
        <Container>
            <Invert data={data}>
                {sus ? <div className={`gracias`}><Button block className={css`${toCSS(button, buttonText)}`} size='lg'>
                    Gracias por suscribirte
                </Button></div> : <div className={`suscribir-panel ${getCSS(input, inputText, button, buttonText)}`}>
                    <FormGroup style={{ gridArea: 'z' }}>
                        <Input valid={valid} value={nombre} setData={setNombre} placeholder="Ingresa tu nombre"
                            title={!valid ? "Ingresa tu nombre" : "Debe colocar el nombre"} next={focus} name='name' />
                        <Input valid={valid} value={correo} setData={setCorreo} placeholder="Ingresa su telefono"
                            title={!valid ? "Ingresa su telefono" : "Debe colocar el telefono"}
                            id='correo' next={suscribirse} name='phone' />
                    </FormGroup>
                    <Button style={{ gridArea: 'x' }} onClick={suscribirse} className='btn-block' size='lg'>Suscribirse</Button>
                </div>}
            </Invert>
        </Container>
    </div>
}
function getCSS(color1: string, color2: string, color3: string, color4: string) {
    return css`
    &>div{ 
        background: ${color2} !important;
        border: 2px solid ${color2} !important;
        &>input,&>textarea{${toCSS(color1, color2)}}
    }
    &>button {${toCSS(color3, color4)}}`;
}
function toCSS(back: string, text: string) {
    return `
    background: ${back} !important;
    color: ${text} !important;
    &::placeholder{
        color: ${text} !important;
    }`
}
function val(nombre: string, telefono: string) {
    // eslint-disable-next-line
    const t = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(telefono);
    return (nombre !== '') && (((telefono !== '') && t));
}

