/* eslint-disable react-hooks/rules-of-hooks */
import { useState, createContext, useEffect, useContext } from 'react'
import { CapacitacionFila, CapacitacionNull, conCapacitacion, Capacitacion, arrayToCapacitacion } from '../Programas/Models';
import { programas as p } from './Data'
import { useCambios } from '@/Utils/useActualizador';

export interface ProgramaType {
    programas: CapacitacionFila[]
    actualizar: () => void
}
export const ProgramaContext = createContext<ProgramaType>({
    programas: p, actualizar: () => { },
});
export default function ProgramaProvider({ children }: { children: any }) {
    const [programas, actualizar] = useCambios<CapacitacionFila>(conCapacitacion(), p, arrayToCapacitacion);
    return <ProgramaContext.Provider value={{ programas, actualizar }}>
        {children}
    </ProgramaContext.Provider>
}
export const SeccionConsumer = ProgramaContext.Consumer;

export function findProgram(id: number | string, def = CapacitacionNull): CapacitacionFila | Capacitacion {
    const { programas } = useContext(ProgramaContext);
    const [programa, setProgram] = useState<any>(programas.find(p => p.id === id || p.codigo === id));
    useEffect(() => { conCapacitacion().detalle(id, setProgram) }, [id]);
    return programa ? programa : def;
}
export function findProgramFila(id: number | string, def = CapacitacionNull): CapacitacionFila {
    const { programas } = useContext(ProgramaContext);
    const [programa] = useState<any>(programas.find(p => p.id === id || p.codigo === id));
    return programa ? programa : def;
}
export function programToId(id: number | string, def = CapacitacionNull): number {
    const { programas } = useContext(ProgramaContext);
    const programa = programas.find(p => p.id === id || p.codigo === id);
    return (programa ? programa : def).id;
}
export function getProgramas() {
    return useContext(ProgramaContext).programas;
}