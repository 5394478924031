import { useParams } from "react-router-dom";
import { programToId } from "../../../../Context/Programas";
import { conCapacitacion } from "../../../Models";
import Telefono2 from "../../../../Paneles/Suscribirse/Telefono";

class Tel2 extends Telefono2 {
    send = () => {
        const { pais, correo, estado, ciudad, telefono, nombre, phone } = this.state;
        const { id } = this.props;
        if (this.getValidation())
            conCapacitacion().Suscribir(id, { pais, correo, estado, ciudad, telefono: `+${phone}-${telefono}`, nombre },
                () => this.setState({ suscrito: true }), error => this.setState({ error }))
    }
}
export default function Telefono({ html, online }: { html: any, online?: boolean }) {
    const id = programToId(useParams<{ id: any }>().id);
    return <Tel2 id={id} online={online} html={html} />
}
