import { Modal } from 'react-bootstrap';
import Recuperar from '../Autenticacion/Recuperar';
import './Modal.css';
interface Props {
    open: boolean,
    close: () => void,
}
export default function ModalRecuperar({ close, open }: Props) {
    return <Modal show={open} centered onHide={close}>
        <Modal.Header closeButton>
            <h2 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                <span><b>Enviar un mensaje para recupear la clave</b></span>
            </h2>
        </Modal.Header>
        <Modal.Body>
            <Recuperar close={close} />
        </Modal.Body>
    </Modal>
}