import { createContext, useContext } from 'react';
import { findEventoFila } from '../../Context/Eventos';
import { useID } from '@/Utils/ReactUtils';
import { useLanding } from '@/Utils/useActualizador';
export interface LandingType {
    panels: any[];
    actualizar: () => void;
    cargando: boolean
}
export const LandingContext = createContext<LandingType>({ panels: [], actualizar: () => { }, cargando: true })
export default function LandingProvider({ children }: { children: any }) {
    const p = findEventoFila(useID());
    const [panels, cargando, actualizar] = useLanding('evento', p.id);
    return <LandingContext.Provider value={{ panels, actualizar, cargando }}>
        {children}
    </LandingContext.Provider>
}
export const SeccionConsumer = LandingContext.Consumer;
export const useLandingEvento = () => useContext(LandingContext);